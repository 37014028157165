import React from 'react';
import '../bottom.css'; // Ensure this path is correct

const TermsAndConditions = () => {
  return (
    <div className="main-container"> {/* This wraps the content and bottom bar */}
      <div className="content"> {/* This takes up the available space */}
        <h1 style={{ color: '#ffcc00' }}>Terms and Conditions</h1>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          Welcome to PlaySeed Games! These Terms and Conditions govern your use of our website and services. By accessing or using our platform, you agree to be bound by these terms.
        </p>
        <h2 style={{ color: '#333' }}>1. Acceptance of Terms</h2>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          By using PlaySeed Games, you acknowledge that you have read, understood, and agree to comply with these Terms and Conditions. If you do not agree to these terms, please refrain from using our services.
        </p>
        <h2 style={{ color: '#333' }}>2. Use of Services</h2>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          You are responsible for maintaining the confidentiality of your account and password. You agree not to use the services for any illegal or unauthorized activities.
        </p>
        <h2 style={{ color: '#333' }}>3. Intellectual Property</h2>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          All content, including games, logos, graphics, and text on PlaySeed Games, is the property of PlaySeed Games and is protected by intellectual property laws.
        </p>
        <h2 style={{ color: '#333' }}>4. Privacy Policy</h2>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          Your use of PlaySeed Games is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review our Privacy Policy to understand how we handle your data.
        </p>
        <h2 style={{ color: '#333' }}>5. Changes to Terms</h2>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          PlaySeed Games reserves the right to modify or update these Terms and Conditions at any time. Any changes will be posted on this page, and by continuing to use our services, you agree to the updated terms.
        </p>
      </div>

     
    </div>
  );
};

export default TermsAndConditions;
