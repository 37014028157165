import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Games from './components/games';
import PlayGame from './components/PlayGame';
import Trending from './components/trending';
import About from './components/aboutus';
import Privancy from './components/privancy';
import { gameData } from './components/allgame';
import Terms from './components/terms';
import Contact from './components/contact';
import Layout from './components/Layout'; // Import the Layout component
import Navbar from './components/Navbar';

function App() {
  return (
    <Router>
      <Routes>
        {/* Wrap all routes inside the Layout component */}
        <Route element={<Layout />}>
          {/* Home Route */}
          <Route path="/" element={<Games />} />
        
          {/* Play Game Route */}
          <Route path="/play-game/:id" element={<PlayGame data={gameData} />} />
          
          {/* Other Routes */}
          <Route path='/AboutUs' element={<About />} />
          <Route path='/PrivacyPolicy' element={<Privancy />} />
          <Route path='/Terms&Condition' element={<Terms />} />
          <Route path='/TrendingGames' element={<Trending />} />
          <Route path='/Contact' element={<Contact />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
