import React from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link
import '../PlayGame.css'; // Updated to include styles from the Games component
import { gameData } from './allgame'; // Import game data

function PlayGame() {
  const { id } = useParams();

  // Find the current game based on the id from the URL (ensure type matching)
  const game = gameData.find((game) => String(game.id) === String(id));

  // Filter other games for suggestions (exclude the current game)
  const otherGames = gameData.filter((game) => game.id !== id);

  // If game not found, display an error message
  if (!game) {
    return <div className="error-message">Game not found</div>;
  }

  return (
    <div className="playgame-container">
      <div className="game-display">
        <h1 className="game-title">{game.title}</h1>
        <iframe
          src={game.url || 'about:blank'} // Fallback URL if none is provided
          title={game.title}
          className="game-iframe"
          loading="lazy" // Lazy loading for better performance
        />
      </div>

      {otherGames.length > 0 && (
        <div className="suggested-games">
          <h2 className="section-title">Other Games You May Like</h2>
          <div className="suggested-games-list">
            {otherGames.map((suggestedGame) => (
              <Link
                key={suggestedGame.id}
                to={`/play-game/${suggestedGame.id}`} // Correct route
                className="suggested-game-card"
              >
                <img
                  src={suggestedGame.thumb}
                  alt={suggestedGame.title}
                  className="game-thumbnail"
                />
                <h3 className="game-card-title">{suggestedGame.title}</h3>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PlayGame;
