import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../bottom.css';

const Contact = () => {
  // State to manage form inputs
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setemail] = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    if (!name || !mobile || !address || !email) {
      toast.error("Please fill all fields.");
      return;
    }

    // Logic to send email (you can integrate with an API here to send the email)
    try {
      // For now, we'll just display a success message
      toast.success("Your message has been sent successfully!");
      setTimeout(() => {
        window.location.reload(); // This will reload the page after 2 seconds
      }, 1000);
    } catch (error) {
      toast.error("There was an error sending your message.");
    }
  };

  return (
    <div style={{ padding: '40px', fontFamily: 'Arial, sans-serif', }}>
      <h1 style={{ color: '#ffcc00', textAlign: 'center', marginBottom: '30px' }}>Contact Us</h1>
      <div className="game-section" style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid #ddd' }}>
        <h2 style={{ color: '#333', textAlign: 'center', marginBottom: '20px' }}>We'd Love to Hear From You</h2>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <input
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              padding: '12px',
              borderRadius: '5px',
              border: '2px solid #ccc',
              fontSize: '16px',
              backgroundColor: '#f9f9f9',
              transition: 'border-color 0.3s ease',
            }}
            onFocus={(e) => e.target.style.borderColor = '#ffcc00'}
            onBlur={(e) => e.target.style.borderColor = '#ccc'}
          />
          <input
            type="text"
            placeholder="Enter your mobile number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            style={{
              padding: '12px',
              borderRadius: '5px',
              border: '2px solid #ccc',
              fontSize: '16px',
              backgroundColor: '#f9f9f9',
              transition: 'border-color 0.3s ease',
            }}
            onFocus={(e) => e.target.style.borderColor = '#ffcc00'}
            onBlur={(e) => e.target.style.borderColor = '#ccc'}
          />
           <input
            type="text"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            style={{
              padding: '12px',
              borderRadius: '5px',
              border: '2px solid #ccc',
              fontSize: '16px',
              backgroundColor: '#f9f9f9',
              transition: 'border-color 0.3s ease',
            }}
            onFocus={(e) => e.target.style.borderColor = '#ffcc00'}
            onBlur={(e) => e.target.style.borderColor = '#ccc'}
          />
          <textarea
            placeholder="Enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            rows="5"
            style={{
              padding: '12px',
              borderRadius: '5px',
              border: '2px solid #ccc',
              fontSize: '16px',
              backgroundColor: '#f9f9f9',
              transition: 'border-color 0.3s ease',
            }}
            onFocus={(e) => e.target.style.borderColor = '#ffcc00'}
            onBlur={(e) => e.target.style.borderColor = '#ccc'}
          />
          <button
            type="submit"
            style={{
              padding: '14px 20px',
              backgroundColor: '#ffcc00',
              border: 'none',
              borderRadius: '5px',
              fontSize: '16px',
              color: 'white',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#ffb900'}
            onMouseLeave={(e) => e.target.style.backgroundColor = '#ffcc00'}
          >
            Submit
          </button>
        </form>
      </div>
      <ToastContainer
  position="bottom-right"  // Ensure to position at the bottom-right
  autoClose={3000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
/>
    </div>
  );
};

export default Contact;
