import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../games.css'
import '../bottom.css'
import {Link, useNavigate } from 'react-router-dom';

const Games = () => {
    const [data, setData] = useState([]);
    const [actionGames, setActionGames] = useState([]); 
    const [searchQuery, setSearchQuery] = useState("");
    const [menuOpen, setMenuOpen] = useState(false); 
    const navigate = useNavigate();

    const toggleMenu = () => {
      setMenuOpen(!menuOpen); // Toggle menu state on hamburger click
    };
    const handleSearch = (event) => {
      setSearchQuery(event.target.value);
    };

    // Filter games based on search query
  const filteredGames = data.filter((game) =>
    game.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

    useEffect(() => {
        // Mocked game data
        const gameData = [
            {
              "id": "32768",
              "title": "Dino Simulator City Attack",
              "description": "Its 2024 and dinosaurs are not only extinct, but they live peacefully with humans in the city. Well, not quite peacefully! In this realistic dinosaur game, you can choose from different species of these giant animals and you can move, run, destroy, smash, jump, disrupt and literally any action you want in the free 3D world. There are different levels to unlock and different missions to complete in each level. You also have the option to select your favorite dinosaur from the available species.",
              "instructions": "Instructions Take control of your favorite dinosaur and explore the entire map in each level look for the targets before time runs out to complete your missions Controls WASD to move Spacebar to jump Ctr to Run F to interact",
              "url": "https://html5.gamemonetize.com/3jeh3dg86fnn2nlnjjqq2y8dsru7ld3c/",
              "category": "Action",
              "tags": "destroying, Dinosaur, kiz10, run",
              "thumb": "https://img.gamemonetize.com/3jeh3dg86fnn2nlnjjqq2y8dsru7ld3c/512x384.jpg",
              "width": "900",
              "height": "600"
            },
            {
              "id": "32767",
              "title": "Dentist Doctor Game For Kids",
              "description": "Have you ever wanted to be a dentist? Our game can fulfill your desire if you want to be a doctor. In the game, you will play as an oral care doctor who performs different types of dental surgery in a dental clinic. You must perform fillings, cleanings, extractions, stain removal, teeth whitening, and other operations to provide the best oral care for the animals.",
              "instructions": "Mouse click or tap to play ",
              "url": "https://html5.gamemonetize.com/7im5z5sp4512qpsp9x6q0mgp1vbsqz10/",
              "category": "Puzzles",
              "tags": "Animal, Doctor",
              "thumb": "https://img.gamemonetize.com/7im5z5sp4512qpsp9x6q0mgp1vbsqz10/512x384.jpg",
              "width": "1080",
              "height": "1920"
            },
            {
              "id": "32766",
              "title": "Lily Style Dress Up",
              "description": "This is a doll dress-up game for girls. Do you like to dress up dolls? Do you want to make a unique doll for yourself? Our game can fulfill your needs.",
              "instructions": "Mouse click or tap to play ",
              "url": "https://html5.gamemonetize.com/ecl4umbfntze1yt9v5wbov4b89f3nipb/",
              "category": "Hypercasual",
              "tags": "Dress Up, Fun",
              "thumb": "https://img.gamemonetize.com/ecl4umbfntze1yt9v5wbov4b89f3nipb/512x384.jpg",
              "width": "720",
              "height": "1280"
            },
            {
              "id": "32765",
              "title": "Basketball Life 3d",
              "description": "Ready for the wonderful basketball challenge? Basketball Life 3D is a fun and creative sports game that tests your skills and timing! From classic hoop challenges to trick shots, every level is packed with excitement.",
              "instructions": "Mouse click or tap to play ",
              "url": "https://html5.gamemonetize.com/47s9m0brc0yi9uxuiox53uov9hoyy40n/",
              "category": "Hypercasual",
              "tags": "Basketball, Shooter, Sport",
              "thumb": "https://img.gamemonetize.com/47s9m0brc0yi9uxuiox53uov9hoyy40n/512x384.jpg",
              "width": "720",
              "height": "1280"
            },
            {
              "id": "32764",
              "title": "Cute Christmas Animals Jigsaw",
              "description": "Cute Christmas Animals Jigsaw is a fun and festive puzzle game designed for kids! It features three adorable animals dressed in cheerful Christmas costumes: a raccoon in a Santa hat, a bunny with a holiday scarf, and a squirrel holding a gift. Kids can choose from three colorful puzzles, improving their problem-solving skills and hand-eye coordination as they play. The game offers simple controls for young players, vibrant illustrations, and a cheerful holiday soundtrack to set the perfect Christmas mood.",
              "instructions": "use mouse or touch the screen",
              "url": "https://html5.gamemonetize.com/wlw4fy6yuuazzvblnru0uupy7ekhfij4/",
              "category": "Puzzles",
              "tags": "Cartoon, Jigsaw",
              "thumb": "https://img.gamemonetize.com/wlw4fy6yuuazzvblnru0uupy7ekhfij4/512x384.jpg",
              "width": "900",
              "height": "600"
            },
            {
              "id": "32763",
              "title": "Cowboy Survival Zombie",
              "description": "A game where you are a cowboy in the old west full of zombies. In this game you can sow, harvest, eat, gather resources, build your shelter and shoot the zombies. A completely destructible world. Your goal will be to survive as many days as possible and kill as many zombies.",
              "instructions": " larr A Move left rarr D Move right uarr W Jump Climb ladder darr S Climb down ladder Space bar Shift Pick up item Open door Use well Use bed Left mouse click Shoot Use item Right mouse click Change figure build",
              "url": "https://html5.gamemonetize.com/r0fkcmgbqnoa1v94n4kp2mblrv7ug3ng/",
              "category": "Shooting",
              "tags": "1 Player, 2D, Action, apocalypse, construction, Enemies, Horror, HTML5, Platform, Shoot, Shooter, survival, Violence, Zombie",
              "thumb": "https://img.gamemonetize.com/r0fkcmgbqnoa1v94n4kp2mblrv7ug3ng/512x384.jpg",
              "width": "800",
              "height": "450"
            },
            {
              "id": "32762",
              "title": "Sprunki in Backrooms Christmas Skibidi Terrors",
              "description": "Now Sprunki must escape from Skibidi Toilet in Christmas day. Get all coin to get access to exit. Backroom is so creepy in christmas!",
              "instructions": "WASD to move",
              "url": "https://html5.gamemonetize.com/1dws9womnudxsiqkh056yumex38z3x3v/",
              "category": "Adventure",
              "tags": "Christmas, huggy, Skibidi Toilet, Sprunki",
              "thumb": "https://img.gamemonetize.com/1dws9womnudxsiqkh056yumex38z3x3v/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32761",
              "title": "MR RACER : Car Racing",
              "description": "MR RACER - Car Racing is a thrilling and challenging racing game that will excite you! Race at high speed with stunning supercars and beat the traffic. This game provides a very easy-to-control car movement and is extremely fun to race. Key features: 100 levels in Challenge Mode. Unlimited levels in Chase Mode. Best of race, chase your opponents and show them that you are a Master! Career Race Mode: Beat your rivals and be the legend! 15 Supercars available Upgrade cars to gear up the performance! Customize cars with attractive car paint and wheels Stunning 3D graphics &amp;amp; realistic lighting Different Camera angles",
              "instructions": "W up arrow key accelerate A left arrow key steer left D right arrow key steer right S down arrow key brake H horn",
              "url": "https://html5.gamemonetize.com/y34098moqvr8ueuddbb4vnwxmtorlq4i/",
              "category": "Racing",
              "tags": "3D, Best Games, Car, Police, Race, Racing, Supercars, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/y34098moqvr8ueuddbb4vnwxmtorlq4i/512x384.jpg",
              "width": "960",
              "height": "540"
            },
            {
              "id": "32760",
              "title": "Sprunki Incredibox Memory",
              "description": "This time in the game Sprunki Incredibox Memory you will need to improve your memory. Cards with the image of Sprunki will appear on the playing field. Each character has a double. Remember the location of the heroes. After a few seconds, the cards will turn towards you with the side that will be the same for everyone. You must click on the cards to find paired Sprunks. Please note that you have a limited number of steps, you will find their limit at the top in Sprunki Incredibox Memory.",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/fnp0ngnsuu5x0bh4lnve42hwx4vc3wcm/",
              "category": "Puzzles",
              "tags": "Fun, Funny, Kids, Memory, Music, Skill",
              "thumb": "https://img.gamemonetize.com/fnp0ngnsuu5x0bh4lnve42hwx4vc3wcm/512x384.jpg",
              "width": "1920",
              "height": "1080"
            },
            {
              "id": "32759",
              "title": "Brick Breaker Ball",
              "description": "Brick Breaker is a classic high-paced game of brick-smashing action. Grab power-ups and extra lives before they fall. Can you set a new high score?",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/xg9x94wxgkn2jzndhoielaq4b7jvsxap/",
              "category": "Shooting",
              "tags": "Arcade, Ball, Bricks, Shooter",
              "thumb": "https://img.gamemonetize.com/xg9x94wxgkn2jzndhoielaq4b7jvsxap/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32758",
              "title": "Scroll and Spot",
              "description": "Experience the joy of the holidays with Scroll and Spot, a unique Christmas-themed puzzle game! Explore two beautifully crafted images as you scroll to uncover five subtle differences hidden within.The scrolling feature keeps both images perfectly aligned, making it a dynamic and immersive puzzle experience. Challenge your observation skills and celebrate the holiday spirit with every discovery!",
              "instructions": "Drag the screen to scroll tap to spot the differences and find all 5 differences to advance to the next level ",
              "url": "https://html5.gamemonetize.com/8jxfjtyj3zka0yqz6i8qvr04lmcg8hb8/",
              "category": "Puzzles",
              "tags": "Christmas, Clicker, Difference, Holiday, Puzzle, Side Scrolling",
              "thumb": "https://img.gamemonetize.com/8jxfjtyj3zka0yqz6i8qvr04lmcg8hb8/512x384.jpg",
              "width": "600",
              "height": "800"
            },
            {
              "id": "32757",
              "title": "Colorful Assort Game",
              "description": "Colorful Assort is a fun and vibrant puzzle game where you match and sort colorful balls. Test your strategy skills as you tackle various levels and challenges. Perfect for a quick and relaxing brain teaser!",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/92rgvlnfyvms2w91puiip5hr27x4y3uj/",
              "category": "Puzzles",
              "tags": "Brain, Casual, Matching, Strategy",
              "thumb": "https://img.gamemonetize.com/92rgvlnfyvms2w91puiip5hr27x4y3uj/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32756",
              "title": "Park The Taxi 3",
              "description": "Park The Taxi 3 is a car driving game. Your task is to drive a taxi and park it into marked parking space. Park the taxi quickly to earn score points and unlock various achievements.",
              "instructions": "Desktop Arrow keys or W A S D to drive the car Mobile Use on-screen buttons to drive ",
              "url": "https://html5.gamemonetize.com/03apdilu81bow5i7jv48mq3kno8ahl02/",
              "category": "Racing",
              "tags": "Android, Car, carparking, Cars, Driving, HTML5, iPhone, Mobile, Parking, Taxi",
              "thumb": "https://img.gamemonetize.com/03apdilu81bow5i7jv48mq3kno8ahl02/512x384.jpg",
              "width": "900",
              "height": "506"
            },
            {
              "id": "32755",
              "title": "Santa Dentist",
              "description": "Get ready for a toothy holiday adventure with Santa Dentist! Step into Santas dental clinic and help him and his festive friends achieve sparkling smiles. Clean cavities, fix broken teeth, and apply braces to naughty or nice patients like Rudolph, elves, and even the Grinch! With colorful tools, fun mini-games, and holiday cheer, youll keep Santas smile shining bright for Christmas. Perfect for kids and families, this game combines learning and laughter for hours of fun. Download Santa Dentist and spread smiles this holiday season!",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/hb6b8tf7j4ot7v6qaia64199f2kvqtgu/",
              "category": "Clicker",
              "tags": "Baby Games, Casual, Doctor, HTML, mapi games, Simulation",
              "thumb": "https://img.gamemonetize.com/hb6b8tf7j4ot7v6qaia64199f2kvqtgu/512x384.jpg",
              "width": "768",
              "height": "1024"
            },
            {
              "id": "32754",
              "title": "Heroic Dash World",
              "description": "This new game brings a whole new world of adventure. In Hero Dash World, youll go on an adventure with a little hero as he explores a fantastical realm in search of the most exciting levels! Play free, brand-new games on kiz10.com every day. The heros adventure begins when he or she turns around and jumps from platform to platform. To purchase heroes, amass as much gems as possible. Following the arrows will show you when to turn, so you can guide the small character down the path. Get the most coins and unlock more characters as you go.",
              "instructions": "Relive as many times as necessary and repeat the levels to improve your score Have fun with this beautiful game Use the mouse or touch the screen to change the direction ",
              "url": "https://html5.gamemonetize.com/pj94bkx8k8tu202htdwf1dc0u1mdgaz5/",
              "category": "Clicker",
              "tags": "Casual, kiz10, Level, run",
              "thumb": "https://img.gamemonetize.com/pj94bkx8k8tu202htdwf1dc0u1mdgaz5/512x384.jpg",
              "width": "700",
              "height": "900"
            },
            {
              "id": "32753",
              "title": "Survev.io",
              "description": "Survev.io Battle Royale is a thrilling multiplayer game where you fight to be the last character standing on a shrinking island. Start with nothing but your fists, scavenge for weapons and supplies, and stay within the safe zone. Choose between solo, duo, or squad modes, and use strategy and skills to outlast up to 50 opponents.",
              "instructions": "Movement W A S D Aim Mouse Melee Shoot Left-Click Change Weapons 1 through 4 or Scroll Wheel Stow Weapons Melee Mode 3 or E Swap to Previous Weapon Q Switch Gun Slots T or drag gun to other slot Reload R Scope Zoom Left-click on Zoo",
              "url": "https://html5.gamemonetize.com/q9xrmuceu30pey8llzop5cr0p1p2fbx9/",
              "category": "Multiplayer",
              "tags": ".io, Battle, Multiplayer, Shoot 'Em Up, Shooter",
              "thumb": "https://img.gamemonetize.com/q9xrmuceu30pey8llzop5cr0p1p2fbx9/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32752",
              "title": "Hoop KIngs",
              "description": "Hoop Kings is a casual puzzle game where players aim to score points by solving basketball-themed challenges. With engaging gameplay, vibrant graphics, and a mix of strategy and fun, it&amp;rsquo;s perfect for quick entertainment or longer play sessions.",
              "instructions": "How to play Keyboard Use the arrow keys to swipe the ball left right up or down Touch Screen Swipe left right up or down to control the basketballs movement ",
              "url": "https://html5.gamemonetize.com/8gjrfuo4objslb0okt96n8qo41flqrpj/",
              "category": "Puzzles",
              "tags": "Arcade, Basketball, Puzzle",
              "thumb": "https://img.gamemonetize.com/8gjrfuo4objslb0okt96n8qo41flqrpj/512x384.jpg",
              "width": "4600",
              "height": "2000"
            },
            {
              "id": "32751",
              "title": "Connect the Images",
              "description": "In Connect Image, players are challenged to complete fragmented pictures by matching pieces to their corresponding shaded outlines. The objective is simple but engaging: arrange various parts of an image onto the shaded areas, gradually revealing the full picture as you progress. Each puzzle features distinct sections that need to be carefully connected, testing both your visual perception and problem-solving skills. As you place the image parts in the right spots, the incomplete image slowly transforms into a complete and vivid picture, offering a satisfying sense of accomplishment. The difficulty increases with each level, introducing more complex images and intricate pieces, providing a fun and rewarding experience for players of all skill levels. Whether youre looking to relax or challenge your mind, Connect Image offers an addictive puzzle experience that will keep you hooked for hours!",
              "instructions": "Slide the images at the right position to play this game ",
              "url": "https://html5.gamemonetize.com/wigmwb0qrjycip6o6p7of5984t77ydql/",
              "category": "Puzzles",
              "tags": "Kids, Matching, Puzzle",
              "thumb": "https://img.gamemonetize.com/wigmwb0qrjycip6o6p7of5984t77ydql/512x384.jpg",
              "width": "720",
              "height": "1280"
            },
            {
              "id": "32750",
              "title": "Checkmate Clash",
              "description": "Checkmate Clash is the ultimate online chess experience where strategy meets competition! Outsmart opponent in thrilling matches and show off your tactical brilliance. Whether youre a chess master or a beginner, this game offers exciting challenges to sharpen your skills. Plan your moves, dominate the board, and deliver the perfect checkmate. Are you ready to claim victory? The battle for the crown begins now!",
              "instructions": "Mouse to play ",
              "url": "https://html5.gamemonetize.com/0e8bl4kywmf7kikoysg3y06o0ymk0gi2/",
              "category": "Puzzles",
              "tags": "Arcade, Board, Boys, Brain, Classic, Hypercasual, School, Skill",
              "thumb": "https://img.gamemonetize.com/0e8bl4kywmf7kikoysg3y06o0ymk0gi2/512x384.jpg",
              "width": "468",
              "height": "1366"
            },
            {
              "id": "32749",
              "title": "Bee keeper",
              "description": "In Beekeeper, begin an exciting adventure in the world of bee farming! This fun simulation game lets you become a beekeeping expert. You need to collect honey and sell it to traders. After you get gold you can upgrade scythe damage and bottle capacity.",
              "instructions": "Mouse click or tap to play ",
              "url": "https://html5.gamemonetize.com/l9ysbr2ma4jmf0y0ozewsaucx4d187am/",
              "category": "Arcade",
              "tags": "Farm, Simulation",
              "thumb": "https://img.gamemonetize.com/l9ysbr2ma4jmf0y0ozewsaucx4d187am/512x384.jpg",
              "width": "750",
              "height": "1334"
            },
           
          ]
          const actionGames = [
            {
              "id": "32768",
              "title": "Dino Simulator City Attack",
              "description": "Its 2024 and dinosaurs are not only extinct, but they live peacefully with humans in the city. Well, not quite peacefully! In this realistic dinosaur game, you can choose from different species of these giant animals and you can move, run, destroy, smash, jump, disrupt and literally any action you want in the free 3D world. There are different levels to unlock and different missions to complete in each level. You also have the option to select your favorite dinosaur from the available species.",
              "instructions": "Instructions Take control of your favorite dinosaur and explore the entire map in each level look for the targets before time runs out to complete your missions Controls WASD to move Spacebar to jump Ctr to Run F to interact",
              "url": "https://html5.gamemonetize.com/3jeh3dg86fnn2nlnjjqq2y8dsru7ld3c/",
              "category": "Action",
              "tags": "destroying, Dinosaur, kiz10, run",
              "thumb": "https://img.gamemonetize.com/3jeh3dg86fnn2nlnjjqq2y8dsru7ld3c/512x384.jpg",
              "width": "900",
              "height": "600"
            },
            {
              "id": "32638",
              "title": "Cleaner Race",
              "description": "Your opponents are not idle bystanders; they are formidable adversaries hell-bent on thwarting your efforts. They deploy tactics designed to disrupt your rhythm, to throw you off course. Yet, you remain steadfast, adapting to their maneuvers with grace and composure. Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/y7pwif6rxy0sn74qsl8cb0o1m3h2y0nt/",
              "category": "Action",
              "tags": "1 Player, Android, Cleaning, Girl, HTML, iPad, iPhone, Kids, Mentolatux, Mobile, Racing",
              "thumb": "https://img.gamemonetize.com/y7pwif6rxy0sn74qsl8cb0o1m3h2y0nt/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32585",
              "title": "Monster truck stunts mega ramps",
              "description": "Experience the adrenaline rush of monster truck stunts on mega ramps. Master gravity-defying jumps, perform daring flips, and conquer challenging obstacles. Showcase your driving skills in high-octane environments designed for ultimate thrill-seekers. Push the limits and dominate the arena with unparalleled stunts and breathtaking maneuvers. Web Dev &lt;a href='https://www.insanegamesonline.com/'&gt;https://www.insanegamesonline.com/&lt;/a&gt;",
              "instructions": "WASD to drive",
              "url": "https://html5.gamemonetize.com/c7ch6q55gha9c8deporwsucmu9xft57q/",
              "category": "Action",
              "tags": "Driving, Mentolatux, monstertruck, Stunts, vehicle",
              "thumb": "https://img.gamemonetize.com/c7ch6q55gha9c8deporwsucmu9xft57q/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32576",
              "title": "Monster Truck Extreme Stunts",
              "description": "Monster Truck Extreme Stunts takes you on an electrifying ride through epic arenas filled with ramps, loops, and daring obstacles. Unleash massive power as you perform jaw-dropping flips, jumps, and tricks. Show off your stunt skills, crush everything in your path, and conquer every extreme course in this high-octane thrill ride! Web Dev &lt;a href='https://www.crazygamesx.com/'&gt;https://www.crazygamesx.com/&lt;/a&gt;",
              "instructions": "WASD to drive your monster truck",
              "url": "https://html5.gamemonetize.com/v4n2fp1keev6q4w8tih0rcxgo0czdaf8/",
              "category": "Action",
              "tags": "Driving, Kids, Mentolatux, monstertruck, Stunts, unity games, vehicle, WebGL",
              "thumb": "https://img.gamemonetize.com/v4n2fp1keev6q4w8tih0rcxgo0czdaf8/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32569",
              "title": "Cargo Truck Montain Simulator",
              "description": "Cargo Truck Mountain Simulator is an immersive driving game that puts you in the drivers seat of a powerful cargo truck, navigating rugged mountain terrain. Your goal is to transport heavy loads across treacherous roads, steep inclines, and unpredictable weather conditions. With realistic physics, dynamic weather, and challenging landscapes. Web Dev &lt;a href='https://www.funcrazygames.com/'&gt;https://www.funcrazygames.com/&lt;/a&gt;",
              "instructions": "WASD to drive",
              "url": "https://html5.gamemonetize.com/dua7f3lyka1ousc42knq5s53pggj2011/",
              "category": "Action",
              "tags": "Driving, Mentolatux, offroad, Simulator, Truck, trucks, unity games, vehicle, WebGL",
              "thumb": "https://img.gamemonetize.com/dua7f3lyka1ousc42knq5s53pggj2011/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32559",
              "title": "American City Truck Transporting",
              "description": "American City Truck Transporting by BestCrazyGames offers thrilling cargo delivery missions through bustling urban landscapes. Navigate challenging roads, manage various trucks, and experience realistic driving gameplay in this action-packed transport game. Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/1nsf9mo5viy91o3xbwdrl6u2yhn5i5o0/",
              "category": "Action",
              "tags": "Driving, Kids, Mentolatux, Simulator, transport, Truck, trucks, Unity3D, vehicle, WebGL",
              "thumb": "https://img.gamemonetize.com/1nsf9mo5viy91o3xbwdrl6u2yhn5i5o0/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32557",
              "title": "Offroad Driving Truck Transport",
              "description": "Offroad Driving Truck Transport is a thrilling adventure where you navigate rugged terrains, muddy paths, and steep hills. Take control of powerful trucks and transport heavy cargo across challenging off-road landscapes. Master your driving skills, overcome obstacles, and feel the adrenaline rush in this ultimate off-road trucking experience! Web Dev &lt;a href='https://www.crazygamesonline.com/'&gt;https://www.crazygamesonline.com/&lt;/a&gt;",
              "instructions": "Use WASD to controll your truck",
              "url": "https://html5.gamemonetize.com/4bk844pemos2hamkblsoxh01dzxbqm3h/",
              "category": "Action",
              "tags": "Driving, Forest, Kids, Levels, Mentolatux, offroad, Simulator, Truck, trucks, Unity3D, vehicle, WebGL",
              "thumb": "https://img.gamemonetize.com/4bk844pemos2hamkblsoxh01dzxbqm3h/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32543",
              "title": "Plane Crash Ragdoll Simulator",
              "description": "As a fearless pilot, your mission is simple: survive a relentless onslaught of rockets targeting your aircraft. But dont be fooled by how easy it looks. Youll feel the rush of adrenaline as you fire rockets at your enemies and fly through dangerous skies! Features: - Many Game Modes: Avoid Rockets, Destroy Enemies and Low Fuel Landing! - Variety of Aircraft: from planes to helicopters - Customizable Skins: unlock various skins for character and aircraft - Realistic Physics &amp;amp; Ragdoll Pilot! - Challenging Levels and Scenarios",
              "instructions": "Aircraft Gas Press the Joystick Aircraft Control Move the Joystick",
              "url": "https://html5.gamemonetize.com/9vz6x0b5dmyk48s8nalz74iupnzh3tyq/",
              "category": "Action",
              "tags": "3D, Action, Air, Aircraft, airplane, Casual, endless, fly, Mobile, Physics, Simulation, Simulator, unity, War",
              "thumb": "https://img.gamemonetize.com/9vz6x0b5dmyk48s8nalz74iupnzh3tyq/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32541",
              "title": "Transport Army vehicle truck driving",
              "description": "Transport vital supplies across rugged terrain, navigating through enemy territory and dangerous landscapes. Complete challenging missions, dodge roadblocks, and upgrade your vehicles armor and speed to overcome obstacles. With realistic controls, customizable trucks, and immersive levels, only the best drivers will lead their convoy to success. Prepare to rev up, strategize, and master the art of military transport in the ultimate army truck driving experience! Web Dev &lt;a href='https://www.insanegamesonline.com/'&gt;https://www.insanegamesonline.com/&lt;/a&gt;",
              "instructions": "WASD to drive the vehicle",
              "url": "https://html5.gamemonetize.com/bi51p6ugftg8ngxfz4x18hw50jskk0fy/",
              "category": "Action",
              "tags": "Army, Driving, Mentolatux, offroad, transport, Truck, trucks, Unity3D, vehicle, WebGL",
              "thumb": "https://img.gamemonetize.com/bi51p6ugftg8ngxfz4x18hw50jskk0fy/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32531",
              "title": "Oil Tank Truck Driving Sim",
              "description": "Oil Tank Truck Driving Sim offers a realistic experience of transporting oil across diverse terrains, featuring authentic physics and traffic scenarios. While it provides an immersive trucking experience, similar games like Oil Tanker Truck Drive and Oil Truck Games: Driving Games also offer comparable gameplay elements. Web Dev &lt;a href='https://www.crazygamesx.com/'&gt;https://www.crazygamesx.com/&lt;/a&gt;",
              "instructions": "WASD to drive",
              "url": "https://html5.gamemonetize.com/9u4hs7eoy90iuifdvh89k5l5h60x0tab/",
              "category": "Action",
              "tags": "Mentolatux, Simulator, transport, Truck, trucks, Unity3D, vehicle, WebGL",
              "thumb": "https://img.gamemonetize.com/9u4hs7eoy90iuifdvh89k5l5h60x0tab/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32519",
              "title": "Truck Driving Construction Transport",
              "description": "The player takes on the role of a skilled truck driver in the construction transport industry. The objective is to navigate various terrains, loading essential construction materials and equipment onto the truck, then driving carefully to the designated construction site. Challenges include maneuvering through traffic, avoiding hazards, and meeting strict delivery times. Web Dev &lt;a href='https://www.crazygamesonline.com/'&gt;https://www.crazygamesonline.com/&lt;/a&gt;",
              "instructions": "WASD to drive your truck",
              "url": "https://html5.gamemonetize.com/fhqjmji6kcucsvwoaplxbtfxoqpya1pz/",
              "category": "Action",
              "tags": "construction, Driving, Kids, Mentolatux, Simulator, Truck, trucks, vehicle",
              "thumb": "https://img.gamemonetize.com/fhqjmji6kcucsvwoaplxbtfxoqpya1pz/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32509",
              "title": "Fire Truck Driving Simulator 2024",
              "description": "In Fire Truck Driving Simulator 2024, players step into the role of a heroic firefighter, driving powerful fire trucks through busy city streets, rural roads, and treacherous mountain paths. The objective is to reach emergency scenes quickly and efficiently, navigating obstacles and traffic while keeping the team safe. Players must master vehicle controls, use sirens strategically to clear paths, and stay calm under pressure. WASD to drive , Web Dev &lt;a href='https://www.bestcrazygames.com/'&gt;https://www.bestcrazygames.com/&lt;/a&gt;",
              "instructions": "WASD to drive",
              "url": "https://html5.gamemonetize.com/ndh1rwjfkejjktdkkwb2bl80tgpg5l03/",
              "category": "Action",
              "tags": "Fire, firefighters, Mentolatux, Simulator, Truck, trucks, vehicle",
              "thumb": "https://img.gamemonetize.com/ndh1rwjfkejjktdkkwb2bl80tgpg5l03/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32386",
              "title": "Animate.Space: Create Animated GIF!",
              "description": "Animate.Space is a game where you can create cartoons, GIFs, and flipbooks directly in your browser. With almost unlimited frames and a variety of drawing tools (paint bucket, eraser, pixel and vector shapes), you can bring your ideas to life through frame-by-frame animation. Easily manage frames (copy, paste, delete), select, move, rotate, resize objects, and undo actions without limits. Save your projects as animated GIFs or share them online, while features like autoframe drawing, ghost preview, and line control help you create smooth, professional-looking animations. No watermarks, no sign-up required &amp;mdash; just pure creativity!",
              "instructions": "Use mouse to draw and use various drawing tools",
              "url": "https://html5.gamemonetize.com/zvluq2l3w4mxywskmoca63u5mm21uvd6/",
              "category": "Action",
              "tags": "Cartoon, Design, Draw, Drawing, Stickman",
              "thumb": "https://img.gamemonetize.com/zvluq2l3w4mxywskmoca63u5mm21uvd6/512x384.jpg",
              "width": "960",
              "height": "600"
            },
            {
              "id": "32345",
              "title": "American Block Sniper Survival Online",
              "description": "In the first-person action game American Block Sniper Online, set in the Minecraft universe, your objective is to clear each level of all adversaries before the timer runs out. You begin each level with only a rifle, but youll discover and equip a wide variety of weaponry as you go. It takes anything from two to five minutes to complete a level, give or take depending on your score. At this point, you should aim to vanquish every enemy that shows up on the map.",
              "instructions": "Instructions Move around the map between buildings collect weapons to eliminate enemies positioned throughout the city W A S D to walk around Mouse to Look around Left Mouse Button to Fire Right Mouse Button to Aim Mouse Wheel To Change Weapons G for G",
              "url": "https://html5.gamemonetize.com/dy2tps9hmgrhuwhk557cdykcx3puevbb/",
              "category": "Action",
              "tags": "kiz10, mineblock, Shooting, Sniper",
              "thumb": "https://img.gamemonetize.com/dy2tps9hmgrhuwhk557cdykcx3puevbb/512x384.jpg",
              "width": "900",
              "height": "600"
            },
            {
              "id": "32287",
              "title": "Warriors Line Up",
              "description": "Immerse yourself in a simple yet thrilling gameplay experience filled with dynamic skills, stunning graphics, and a plethora of challenging enemies. With intuitive mobile controls, you can move swiftly, launch attacks, and deploy powerful skills. Engage in addictive battles that showcase a diverse array of combat techniques, promising an enthralling and captivating adventure for all players.",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/qxne2dcajybwp6dh2txkiignwfyp569x/",
              "category": "Action",
              "tags": "Click, Mentolatux, Sword, Top Down, Unity3D, War, Warrior, WebGL",
              "thumb": "https://img.gamemonetize.com/qxne2dcajybwp6dh2txkiignwfyp569x/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32247",
              "title": "Offroad Truck Army Driving",
              "description": "In Offroad Truck Army Driving, players navigate rugged mountain terrains and diverse environments. With three types of military vehicles, players take on challenging missions across three unique packs. Experience intense off-road action, conquering steep hills, muddy trails, and rocky landscapes as you complete various army driving tasks, all while mastering the toughest off-road challenges. Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
              "instructions": "WASD drive",
              "url": "https://html5.gamemonetize.com/43fe8rc64mstpvw5zf24lct45nptj2mn/",
              "category": "Action",
              "tags": "Army, Driving, Mentolatux, mountain, offroad, Truck, trucks, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/43fe8rc64mstpvw5zf24lct45nptj2mn/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32246",
              "title": "Goodnight My Baby",
              "description": "Are you interested in the world of baby monsters? Do you want to know what the home of the baby monsters looks like when night falls? Our game can satisfy your curiosity. You can go into their home and put the baby to sleep.",
              "instructions": "Mouse click or tap to play ",
              "url": "https://html5.gamemonetize.com/34sllctljd4vlu5n7cno56dm97mlfafb/",
              "category": "Action",
              "tags": "Monster, Panda",
              "thumb": "https://img.gamemonetize.com/34sllctljd4vlu5n7cno56dm97mlfafb/512x384.jpg",
              "width": "1920",
              "height": "1080"
            },
            {
              "id": "32234",
              "title": "Offroad Cargo Truck 2024",
              "description": "The 2024 Offroad Cargo Truck roars through lush forest trails, showcasing its rugged durability and advanced suspension. Equipped with high-clearance wheels and all-terrain tires, the driver expertly navigates winding paths. The truck&amp;rsquo;s spacious cargo area carries essential gear, while the vibrant greenery and serene environment enhance the adventurous spirit. Sunlight filters through towering trees, creating a perfect backdrop for offroad exploration. Web Dev &lt;a href='https://www.newcrazygames.com/'&gt;https://www.newcrazygames.com/&lt;/a&gt;",
              "instructions": "WASD drive",
              "url": "https://html5.gamemonetize.com/uayd9hwxcusn9oarblbqypwsq5g520xk/",
              "category": "Action",
              "tags": "Driving, Forest, Mentolatux, offroad, Simulator, Truck, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/uayd9hwxcusn9oarblbqypwsq5g520xk/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32225",
              "title": "Vehicle Transport Police Simulator",
              "description": "Vehicle Transport Police Simulator is an action-packed game where players drive various vehicles, including buses, jeeps, and trucks, to transport military personnel to their destinations. With challenging missions, diverse terrains, and realistic driving mechanics, players must navigate obstacles and ensure safe, timely delivery of troops while maintaining law and order. Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
              "instructions": "WASD to drive",
              "url": "https://html5.gamemonetize.com/sro33w89adskjdtlk9cwktqjdd1csm90/",
              "category": "Action",
              "tags": "Army, bus, Car, Cars, Driving, Mentolatux, military, Simulation, Truck, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/sro33w89adskjdtlk9cwktqjdd1csm90/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32217",
              "title": "Truck Simulator Construction",
              "description": "Truck Simulator Construction is a realistic simulation game where players take control of various heavy-duty trucks and machinery to complete challenging construction tasks. From transporting materials to operating cranes and excavators, players must manage time and resources to build structures, roads, and more. With immersive environments and detailed vehicles, experience the life of a construction site worker!",
              "instructions": "WASD - drive",
              "url": "https://html5.gamemonetize.com/u06e6by2a28avhtl0x368c7wv2jnh1tz/",
              "category": "Action",
              "tags": "city, Driving, Mentolatux, Simulation, Truck, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/u06e6by2a28avhtl0x368c7wv2jnh1tz/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32191",
              "title": "Fall Guys 2024",
              "description": "Fall Guys 2024 is a vibrant, offline battle royale where players navigate whimsical obstacle courses and compete in zany minigames. With enhanced customization options, seasonal events, and chaotic fun, it challenges players to outwit and outlast others in a series of playful and hilarious rounds. Experience a colorful frenzy of competition and teamwork in this ever-evolving game. Web Dev &lt;a href='https://www.insanegamesonline.com/'&gt;https://www.insanegamesonline.com/&lt;/a&gt;",
              "instructions": "Use Joystick to walk and button SPACE to jump",
              "url": "https://html5.gamemonetize.com/bzv8wjffafspjcaof9hsu8y37iq4toxc/",
              "category": "Action",
              "tags": "Fall Boys, Fall Guys, Fun, Funny, Kids, Mentolatux, Skill, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/bzv8wjffafspjcaof9hsu8y37iq4toxc/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32179",
              "title": "Noob City The Gangster",
              "description": "You get the chance to live out your wildest dreams as a real-life gangster in the thrilling new action game CraftsMan 3D Gangster, which is reminiscent of Minecraft. Other criminals will be waiting to eliminate you when you go out into the open world. Complete this entertaining 3D action game and confront more dangerous foes at each level.",
              "instructions": "What are the finest Roblox games that can be played without downloading them on Kiz10 com Controls Mouse To LookW A S D To DriveSpace To Hand BreakF To Exit Vehicle",
              "url": "https://html5.gamemonetize.com/jsi5x60oq85umikv4g48xnnqms323v4t/",
              "category": "Action",
              "tags": "Fighting, kiz10, run, Shooter",
              "thumb": "https://img.gamemonetize.com/jsi5x60oq85umikv4g48xnnqms323v4t/512x384.jpg",
              "width": "900",
              "height": "600"
            },
            {
              "id": "32135",
              "title": "Auto Bus Driving 2024",
              "description": "Auto Bus Driving 2024 offers a thrilling and realistic driving experience with the latest buses on diverse city routes. Navigate through challenging traffic, weather conditions, and time-sensitive schedules. With enhanced graphics and dynamic controls, this simulation game allows players to master driving skills, manage passengers, and explore detailed urban landscapes. Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
              "instructions": "WASD to drive",
              "url": "https://html5.gamemonetize.com/ypz3l7ed6h45ovjr0k2bg498umzkx7u7/",
              "category": "Action",
              "tags": "bus, city, Driving, Mentolatux, School, Sim, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/ypz3l7ed6h45ovjr0k2bg498umzkx7u7/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32124",
              "title": "Army Bus Driving 2024",
              "description": "Army Bus Driving 2024 immerses players in the challenging world of military transport. Take command of rugged, all-terrain army buses, transporting soldiers and equipment across hostile environments. Navigate through treacherous landscapes, from deserts to mountains, under tight schedules and demanding conditions. With realistic physics, detailed military vehicles, and a variety of missions, this simulation game offers a thrilling and authentic experience for those ready to handle the pressures of army logistics on the road.",
              "instructions": "WASD drive",
              "url": "https://html5.gamemonetize.com/x768a5mk67izz2hv8kxhucmlw49i2ane/",
              "category": "Action",
              "tags": "Action, Army, bus, Driving, Mentolatux, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/x768a5mk67izz2hv8kxhucmlw49i2ane/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32113",
              "title": "Car Stunt Ramp Challenge",
              "description": "Car Stunt Ramp Challenge is an adrenaline-pumping racing game that puts your driving skills to the ultimate test. Get behind the wheel of high-speed cars and take on extreme ramps and obstacle courses designed to push your limits. The game features a variety of stunts, from high-flying jumps to complex flips, all set in vibrant, dynamic environments. Precision and timing are crucial as you race against the clock to complete each challenge. With its realistic physics and thrilling gameplay, Car Stunt Ramp Challenge offers a heart-pounding experience for stunt driving enthusiasts. Are you ready to conquer the ramps?",
              "instructions": "WASD drive",
              "url": "https://html5.gamemonetize.com/omk3dx57djeoq1l1b8kj1r0mz43trze3/",
              "category": "Action",
              "tags": "Car, Driving, Mentolatux, Stunts, Supercars, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/omk3dx57djeoq1l1b8kj1r0mz43trze3/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32097",
              "title": "Real Bus Parking Oick and Drop",
              "description": "Bus Parking: Pick and Drop is an engaging driving simulation game that challenges players to master the art of precision parking while providing a smooth ride for passengers. As a skilled bus driver, your task is to navigate busy city streets, pick up passengers from designated stops, and safely drop them off at their destinations. The game features realistic controls and detailed urban environments, making each level more challenging than the last. Perfecting your parking skills is key to advancing through the levels, ensuring timely and accurate drop-offs. Its a must-try for fans of driving and parking simulation games.",
              "instructions": "WASD drive",
              "url": "https://html5.gamemonetize.com/9gtk3fuj8b3956ikmbul44mumh11hftl/",
              "category": "Action",
              "tags": "bus, carparking, city, Driving, Mentolatux, Parking, Sim, Unity3D",
              "thumb": "https://img.gamemonetize.com/9gtk3fuj8b3956ikmbul44mumh11hftl/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32082",
              "title": "TukTuk Rickshaw City Driving Sim",
              "description": "Step into the role of a legendary city tuk-tuk driver in TukTuk Rickshaw City Driving Sim! Complete your tasks on time to unlock exciting new levels in this thrilling rickshaw driving game. Transport passengers from one point to their destination, mastering the art of precise parking to keep them happy.",
              "instructions": "WASD driving",
              "url": "https://html5.gamemonetize.com/jykqi5zuiofupq2hb774wo0nrtplkfua/",
              "category": "Action",
              "tags": "city, Driving, Rickshaw, Sim, TukTuk, vehicle",
              "thumb": "https://img.gamemonetize.com/jykqi5zuiofupq2hb774wo0nrtplkfua/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32060",
              "title": "Polygon Flight Simulator",
              "description": "Fly like a pro in this airplane simulator; you can smoothly navigate from one airport to another. Its straightforward&amp;mdash;press the W button, drag the mouse, and watch your plane ascend. Skillfully enter airborne checkpoints en route to your next destination airport. With intuitive controls, this simulator provides an immersive flying experience. Whether youre a novice or an expert, the seamless interface ensures an enjoyable journey through virtual skies. Master the art of aviation with this user-friendly simulator. Website Developer &lt;a href='https://www.crazygamesonline.com'&gt;https://www.crazygamesonline.com&lt;/a&gt;",
              "instructions": "WASD move plane Mouse Drag go up Mouse Forwad go Down Mouse Left or right move plane TAB Menu",
              "url": "https://html5.gamemonetize.com/jxcgkmz8u2tpdhvvfed9ecl25bqkspxt/",
              "category": "Action",
              "tags": "Aircraft, airplane, Driving, fly, Mentolatux, Unity3D, vehicle, WebGL",
              "thumb": "https://img.gamemonetize.com/jxcgkmz8u2tpdhvvfed9ecl25bqkspxt/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32058",
              "title": "Imposter Assassin 3D",
              "description": "Play the entertaining 3D action game Imposter Assassin 3D to transform into a true assassin. It has stealth, shooting, action, enemy guards, and a ton of levels and skins to unlock. Assume charge of an imposter who dares to enter the control base. Numerous enemy troops will be patrolling each level, and they will kill you right away if they discover you.",
              "instructions": "Instructions Move stealthily to attack the soldiers from behind so they dont see you to hit the precise attack at the right time and dont leave a single one alive Use the mouse to move and attack Touch the screen and drag on mobile devices ",
              "url": "https://html5.gamemonetize.com/kjixdc9mw89s3k1rdka4eotbmrgggvkn/",
              "category": "Action",
              "tags": "attack, Fighting, Impostor, Shooter",
              "thumb": "https://img.gamemonetize.com/kjixdc9mw89s3k1rdka4eotbmrgggvkn/512x384.jpg",
              "width": "900",
              "height": "600"
            },
            {
              "id": "32053",
              "title": "Water Slide Adventure",
              "description": "Dive into the ultimate water slide adventure with Extreme Water Slide Adventure! Experience the thrill of speeding down giant slides in this offline swimming pool game. As a super slider, youll navigate crazy twists, turns, and daring stunts that will keep you on the edge of your seat. Packed with excitement and fun, this game offers endless enjoyment for all water slide enthusiasts. Are you ready for the ride of your life?",
              "instructions": "WASD - CONTROL the player",
              "url": "https://html5.gamemonetize.com/9jiw9pzfxozl2lnrio0tb50nnwsmwdxq/",
              "category": "Action",
              "tags": "Adventure, Mentolatux, slide, slider, Unity3D, water, waterslide",
              "thumb": "https://img.gamemonetize.com/9jiw9pzfxozl2lnrio0tb50nnwsmwdxq/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32041",
              "title": "Super Hero Sponge",
              "description": "Join the action-packed adventure with Super Hero Sponge! Embark on an epic journey as the brave sponge hero, battling against formidable enemies and monstrous Big Bosses. Team up with friends, overcome challenging obstacles, and save the day with your heroic skills. With thrilling gameplay and exciting challenges, this adventure is perfect for fans of action and fun. Are you ready to become the ultimate Super Hero Sponge? Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
              "instructions": "Controlls WASD Walk X Attack F Fire SPACE Jump",
              "url": "https://html5.gamemonetize.com/wgoul13b9vne3iifz3cpspkq9hf5dcam/",
              "category": "Action",
              "tags": "2D, Mentolatux, Spongebob, Superhero, Unity3D",
              "thumb": "https://img.gamemonetize.com/wgoul13b9vne3iifz3cpspkq9hf5dcam/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "32037",
              "title": "Realistic Lion Hunting Animal 2024",
              "description": "Experience the thrill of the wild in Realistic Lion Hunting Animal 2024! This immersive simulator puts you in the paws of a majestic lion, roaming a vast and realistic forest. Hunt various animals to survive and advance through challenging levels. Explore the dense wilderness, track your prey, and feel the rush of the hunt. Perfect for those seeking adventure and excitement in the animal kingdom. Can you survive the wild?",
              "instructions": "WASD Walk LeftShift Run Mouse Right Stop Run Mouse Left Attack ",
              "url": "https://html5.gamemonetize.com/4xgs7sshdp0wpeqsoztn42pdttjiy5jq/",
              "category": "Action",
              "tags": "Action, Animal, attack, Forest, hunter, lion, Mentolatux, Simulation",
              "thumb": "https://img.gamemonetize.com/4xgs7sshdp0wpeqsoztn42pdttjiy5jq/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31986",
              "title": "Fish Grow Eating Fish",
              "description": "This adventure game is designed to help you unwind! Dive into the underwater world and strive to become the Ocean King. Begin your journey as a small angelfish and grow by quickly consuming smaller fish. The vast ocean is brimming with surprises, so watch out for larger fish that might bite back. Be patient as you grow stronger, and soon youll be able to take on the bigger fish! Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
              "instructions": "WASD controll the fish SPACE attack",
              "url": "https://html5.gamemonetize.com/jfhu1t3dbwz31jo1ka00z0j043vfjzge/",
              "category": "Action",
              "tags": "Action, Android, eating, Fish, HTML5, iPad, iPhone, Kids, Mentolatux, Skill, WebGL",
              "thumb": "https://img.gamemonetize.com/jfhu1t3dbwz31jo1ka00z0j043vfjzge/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31976",
              "title": "Silent Assassin 2024",
              "description": "Enter the world of Silent Assassin 2024, where your mission is to eliminate monsters with precision and stealth. Click on the screen as the wheel spins, strategically selecting weapons to throw at the approaching creatures. With each accurate shot, you inch closer to victory. Web Dev &lt;a href='https://www.game-plays.com/'&gt;https://www.game-plays.com/&lt;/a&gt;",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/bigltd28j37dj9b4n1kz1rufoub9fjyd/",
              "category": "Action",
              "tags": "1 Player, Action, assasin, assassin, Mentolatux, Monster, throwing, Unity3D, weapons, WebGL",
              "thumb": "https://img.gamemonetize.com/bigltd28j37dj9b4n1kz1rufoub9fjyd/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31953",
              "title": "Granny Chapter 3 High School",
              "description": "Chapter 3: High School Escape from Granny is a fun third-person horror game where you must hide from Granny and the specters of Slendrina. Discover the keys to the abandoned schools classrooms, stock up on ammo, and try to stay alive for as long as possible. Find the revolver loaded with ammo and all the keys to the classrooms of the vacant school while Granny and Slendrina chase you. As you go inside the classroom, youll see a single desk that reads Granny and the keys to the locked door. Finally, Slendrina or Granny makes the player",
              "instructions": "The main objective of the game is to try to survive as long as you can by collecting items to hide and escape from Granny Controls WASD to move Right click to shoot F Interact Esc Menu",
              "url": "https://html5.gamemonetize.com/ehnw198451t7cygffbjrs4ac50ue011k/",
              "category": "Action",
              "tags": "Escape, kiz10, Room, Shooting, survival",
              "thumb": "https://img.gamemonetize.com/ehnw198451t7cygffbjrs4ac50ue011k/512x384.jpg",
              "width": "900",
              "height": "600"
            },
            {
              "id": "31950",
              "title": "Swipe To Park The Cars",
              "description": "Swipe To Park The Cars is a captivating 2D mobile game that puts your parking skills to the test. In this addictive title, players must skillfully guide a fleet of colorful cars into their designated parking spots using intuitive swipe controls. The challenge lies in the increasing complexity of parking scenarios, from crowded lots to tight spaces, requiring precision and quick thinking. Web Dev &lt;a href='https://www.crazygamesonline.com/'&gt;https://www.crazygamesonline.com/&lt;/a&gt;",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/0eii7f3a9spn9lw35vo3ovmpur1120tt/",
              "category": "Action",
              "tags": "Android, Cars, Click, HTML5, iPad, iPhone, Mentolatux, Mobile, Mouse, Parking, Skill, Unity3D, WebGL",
              "thumb": "https://img.gamemonetize.com/0eii7f3a9spn9lw35vo3ovmpur1120tt/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31943",
              "title": "StickMan Defense",
              "description": "Dive into the thrilling world of StickMan Defense, a captivating game where strategy meets action. In this game, players are tasked with defending their base against waves of incoming enemies, using a variety of stickman warriors. Each level challenges your tactical skills, requiring careful placement of troops and timely upgrades. With intuitive controls and engaging graphics, StickMan Defense offers an immersive experience for both casual and hardcore gamers. Web Dev &lt;a href='https://www.insanegamesonline.com/'&gt;https://www.insanegamesonline.com/&lt;/a&gt;",
              "instructions": "Mouse click or tap to play",
              "url": "https://html5.gamemonetize.com/xnm06hp9o6dt48fua7g3jzoddqnkc5fs/",
              "category": "Action",
              "tags": "Android, Click, Defense, HTML5, iPad, iPhone, Mentolatux, Mobile, Stickman, WebGL",
              "thumb": "https://img.gamemonetize.com/xnm06hp9o6dt48fua7g3jzoddqnkc5fs/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31816",
              "title": "Knife WAR.IO",
              "description": "Enter Knife WAR.IO, where you face off against hordes of opponents in a fierce battle for dominance! Defeat rivals, gather orbs to level up and earn points. As you progress, grow in size and unlock different skins, evolving into a formidable giant in the arena. Strategize your attacks, dodge enemy knives, and rise to the top of this thrilling multiplayer experience! Website Developer &lt;a href='https://www.bestcrazygames.com'&gt;https://www.bestcrazygames.com&lt;/a&gt;",
              "instructions": "Use joystick to move player and A S D F for attacking other players ",
              "url": "https://html5.gamemonetize.com/xvcym1vb1aesda2konvlw79625pnkr6k/",
              "category": "Action",
              "tags": ".io, .io Games, Knight, Mentolatux, Sword, Unity3D, War, WebGL",
              "thumb": "https://img.gamemonetize.com/xvcym1vb1aesda2konvlw79625pnkr6k/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31801",
              "title": "Craft Theft War",
              "description": "Immerse yourself in the best open-world action-adventure sandbox game, Craft Theft War, inspired by pixel voxel cube craft style online shooting games. Enjoy a vast selection of action-packed games, cool games, and relaxing games, all within a sprawling sandbox environment. This game offers an unparalleled online gaming experience, combining the best elements of open-world adventure, sandbox gameplay, and multiplayer action. Join the fun today and discover why its one of the best online games!",
              "instructions": "Move WASD Aim Mouse Shoot Left Click Jump Space Sprint Shift Reload R ",
              "url": "https://html5.gamemonetize.com/2w7rnj6q5cnt9jt81l0m7s27uqqyqq0f/",
              "category": "Action",
              "tags": "Action, Battle, Bubble Shooter, Multiplayer, Shooting, War",
              "thumb": "https://img.gamemonetize.com/2w7rnj6q5cnt9jt81l0m7s27uqqyqq0f/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31750",
              "title": "Fall Boys Ulimate Tournament 2024",
              "description": "Welcome to the Fall Boys Ultimate Tournament 2024! Step into a vibrant world filled with exhilarating challenges and fierce competition. Test your skills in a variety of whimsical mini-games that will push your agility and strategy to the limit. Whether youre dodging obstacles, racing against the clock, or working with teammates, aim to outlast and outperform your opponents. Website Developer &lt;a href='https://www.game-plays.com/'&gt;https://www.game-plays.com/&lt;/a&gt;",
              "instructions": "WASD to walk SPACE to jump ",
              "url": "https://html5.gamemonetize.com/nxl2ybhls0ev61j3bf9zzfmlwnjzwe58/",
              "category": "Action",
              "tags": "Best Games, Fall Boys, Fall Guys, Mentolatux, Multiplayer, run, running, unity games, WebGL",
              "thumb": "https://img.gamemonetize.com/nxl2ybhls0ev61j3bf9zzfmlwnjzwe58/512x384.jpg",
              "width": "800",
              "height": "600"
            },
            {
              "id": "31749",
              "title": "Escape Wednesday",
              "description": "Break through the monsters to get out of the maze! Many labyrinths will not let you get bored. Show your abilities and you will be the winner. In Wednesday Escape, youll navigate through intricate mazes filled with challenging monsters. Each level presents unique obstacles that test your skills and strategy. Can you outsmart the creatures and find the exit?",
              "instructions": "Move mouse change direction",
              "url": "https://html5.gamemonetize.com/5fw8d2716dxf4t9semyy8pkkfmzpz0jh/",
              "category": "Action",
              "tags": "3D, Escape, Monster, Skill",
              "thumb": "https://img.gamemonetize.com/5fw8d2716dxf4t9semyy8pkkfmzpz0jh/512x384.jpg",
              "width": "800",
              "height": "600"
            }
          ]
        setData(gameData);
        setActionGames(actionGames);
        
    }, []);

    const handleAction = () => {
        toast.success("Action performed successfully!");
    };
    return (
      <div>
        {/* Navbar */}
        <div className="navbar">
          <div className="brand">GamePortal</div>
          <div className="navbar-content">
            <input
              type="text"
              placeholder="Search Games"
              value={searchQuery}
              onChange={handleSearch}
            />
            <div className="hamburger" onClick={toggleMenu}>
              &#9776; {/* Hamburger icon */}
            </div>
            <nav className={menuOpen ? 'open' : ''}>
              <Link to="/">Home</Link>
              <Link to="/TrendingGames">Trending Games</Link>
            </nav>
            <div className="social-links">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
  
        {/* Header Section */}
        <div className="header" style={{ position: 'relative', paddingLeft: '50px', textAlign: 'left', border: '2px solid #ffcc00', borderRadius: '10px', overflow: 'hidden' }}>
          {/* Background Video */}
          <video
            src={require('../video/gameVideo.mp4')}
            type="video/mp4"
            autoPlay
            muted
            loop
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: -1,
            }}
          />
  
          {/* Content */}
          <div className="header-text-container">
            <h1>Have <span style={{ color: '#ffcc00' }}>Endless Fun</span></h1>
            <p>Enjoy an exciting gaming experience without spending a penny, with access to over 20,000 games on our portal. Let the fun begin!</p>
          </div>
          <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
            <button>All Games</button>
            <Link to="/TrendingGames">
              <button className="trending">
                Trending
              </button>
            </Link>
          </div>
        </div>
  
        {/* Game Cards Section */}
        <div className="game-section">
          <h2> 🔥 Hottest Games For You</h2>
          <div className="game-cards">
            {filteredGames.map((game) => (
              <div
                key={game.id}
                className="game-card"
                onClick={() => navigate(`/play-game/${game.id}`)} // Navigate to Play Game page
                style={{ cursor: 'pointer' }} // Show pointer on hover
              >
                <img src={game.thumb} alt={game.title} />
                <p>{game.title}</p>
              </div>
            ))}
          </div>
        </div>
  
        {/* Action Games Section */}
        <div className="game-section action-game-section">
          <h2> ⚔️ Action Games </h2>
          <div className="game-cards">
            {actionGames.map((game) => (
              <div
                key={game.id}
                className="game-card"
                onClick={() => navigate(`/play-game/${game.id}`)} // Navigate to Play Game page
                style={{ cursor: 'pointer' }} // Show pointer on hover
              >
                <img src={game.thumb} alt={game.title} />
                <p>{game.title}</p>
              </div>
            ))}
          </div>
        </div>
  
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  };
  
  export default Games;