import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../games.css';
import { Link, useNavigate } from 'react-router-dom';

const Trending = () => {
    const [data, setData] = useState([]);
    const [actionGames, setActionGames] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Toggle menu state on hamburger click
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter games based on search query
    const filteredGames = data.filter((game) =>
        game.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleAction = () => {
        toast.success("Action performed successfully!");
    };

    useEffect(() => {
        // Mocked game data
        const gameData = [
          {
            "id": "32769",
            "title": "Basketball Life 3d",
            "description": "Ready for the wonderful basketball challenge? Basketball Life 3D is a fun and creative sports game that tests your skills and timing! From classic hoop challenges to trick shots, every level is packed with excitement.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/47s9m0brc0yi9uxuiox53uov9hoyy40n/",
            "category": "Hypercasual",
            "tags": "Basketball, Shooter, Sport",
            "thumb": "https://img.gamemonetize.com/47s9m0brc0yi9uxuiox53uov9hoyy40n/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32763",
            "title": "Cowboy Survival Zombie",
            "description": "A game where you are a cowboy in the old west full of zombies. In this game you can sow, harvest, eat, gather resources, build your shelter and shoot the zombies. A completely destructible world. Your goal will be to survive as many days as possible and kill as many zombies.",
            "instructions": " larr A Move left rarr D Move right uarr W Jump Climb ladder darr S Climb down ladder Space bar Shift Pick up item Open door Use well Use bed Left mouse click Shoot Use item Right mouse click Change figure build",
            "url": "https://html5.gamemonetize.com/r0fkcmgbqnoa1v94n4kp2mblrv7ug3ng/",
            "category": "Shooting",
            "tags": "1 Player, 2D, Action, apocalypse, construction, Enemies, Horror, HTML5, Platform, Shoot, Shooter, survival, Violence, Zombie",
            "thumb": "https://img.gamemonetize.com/r0fkcmgbqnoa1v94n4kp2mblrv7ug3ng/512x384.jpg",
            "width": "800",
            "height": "450"
          },
          {
            "id": "32759",
            "title": "Brick Breaker Ball",
            "description": "Brick Breaker is a classic high-paced game of brick-smashing action. Grab power-ups and extra lives before they fall. Can you set a new high score?",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/xg9x94wxgkn2jzndhoielaq4b7jvsxap/",
            "category": "Shooting",
            "tags": "Arcade, Ball, Bricks, Shooter",
            "thumb": "https://img.gamemonetize.com/xg9x94wxgkn2jzndhoielaq4b7jvsxap/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32752",
            "title": "Hoop KIngs",
            "description": "Hoop Kings is a casual puzzle game where players aim to score points by solving basketball-themed challenges. With engaging gameplay, vibrant graphics, and a mix of strategy and fun, it&amp;rsquo;s perfect for quick entertainment or longer play sessions.",
            "instructions": "How to play Keyboard Use the arrow keys to swipe the ball left right up or down Touch Screen Swipe left right up or down to control the basketballs movement ",
            "url": "https://html5.gamemonetize.com/8gjrfuo4objslb0okt96n8qo41flqrpj/",
            "category": "Puzzles",
            "tags": "Arcade, Basketball, Puzzle",
            "thumb": "https://img.gamemonetize.com/8gjrfuo4objslb0okt96n8qo41flqrpj/512x384.jpg",
            "width": "4600",
            "height": "2000"
          },
          {
            "id": "32719",
            "title": "Sprunki Clicker Game",
            "description": "In Sprunki Clicker, youve got 60 seconds to make as many clicks as possible to make a great score. The faster you click, the more loot you uncover! Keep an eye out for special time bonuses that can extend your run and boost your score. How much clicks can you make before times up? Its a simple Spunki thrilling game for the fastest clickers!",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/4ffxennbgf8psg1sermfk67g3ydqhq0r/",
            "category": "Clicker",
            "tags": "Click, Clicker, Sprunki",
            "thumb": "https://img.gamemonetize.com/4ffxennbgf8psg1sermfk67g3ydqhq0r/512x384.jpg",
            "width": "600",
            "height": "1024"
          },
          {
            "id": "32666",
            "title": "Sprunki PopIt",
            "description": "Sprunki PopIt: The Ultimate Bubble-Popping Adventure! Get ready for hours of bubbly fun with Sprunki PopIt, the free-to-play online game that kids everywhere are going crazy for! It&amp;rsquo;s fast, colorful, and packed with exciting challenges. Your goal? Pop all the bubbles, dodge obstacles, and conquer level after level of pure popping chaos!",
            "instructions": "Mouse to play ",
            "url": "https://html5.gamemonetize.com/wqokz2j4q2iqsmhfgy1ablq82cxpmcvo/",
            "category": "Puzzles",
            "tags": "Bubble, Collecting, Fun, Funny, Girl, HTML5, Hypercasual, Kids",
            "thumb": "https://img.gamemonetize.com/wqokz2j4q2iqsmhfgy1ablq82cxpmcvo/512x384.jpg",
            "width": "480",
            "height": "854"
          },
          {
            "id": "32661",
            "title": "Christmas Sledge Rider 3D",
            "description": "Embark on a thrilling winter adventure with winter sledge adventure, the ultimate christmas game for all ages! Jump onto your sledge and race through stunning, snow landscapes filled with festive cheer. With high-quality graphics that bring the winter wonderland to life, you&amp;rsquo;ll feel the excitement of the season as you sled down icy hills, dodge obstacles, and enjoy endless gameplay. Start playing now and join the fun&amp;mdash;sled your way to victory this holiday season!",
            "instructions": "On desktop Use the A D keys or arrow keys On mobile Hold your finger and slide left-right ",
            "url": "https://html5.gamemonetize.com/aaubvnris6mflicefmtln994mf4d4r7m/",
            "category": "Racing",
            "tags": "Christmas, Race, Racing",
            "thumb": "https://img.gamemonetize.com/aaubvnris6mflicefmtln994mf4d4r7m/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32649",
            "title": "Scary House Clown Evil",
            "description": "Scary House Clown Evil is an Exciting Thriller 3D horror game, inspired by all horror films about the best serial killers in their creepy Mansion. You wake up and find yourself trapped inside a scary House with blood and dark feelings, with deadly silence and a dangerous clown murderer whos looking around for his next victim to kill. On Scary House Clown Evil horror game you have to Sneak with stealth and explore the scary Mansion, discover and escape rooms and find Every single key for each Room and watch out for the Creepy Clown killer, whos always so suspicious about an intruders presence Nobody escaped the dark pursuer. Dare you?",
            "instructions": "WSQD For Movement Left Shift To Sprint Mobile Virtual Joystick for Movement Touch Screen to look around",
            "url": "https://html5.gamemonetize.com/44z7cekal1224m2ngzkxkekhgdmn001d/",
            "category": "Adventure",
            "tags": "3D Games, Best Games, Escape, free games for your website, Horror",
            "thumb": "https://img.gamemonetize.com/44z7cekal1224m2ngzkxkekhgdmn001d/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32641",
            "title": "Adventure Home",
            "description": "Welcome to Adventure Home: Run Adventure, the ultimate mobile game that will take you on a thrilling journey of running, jumping, and treasure collecting! If you like genres such as adventure, action, rpg, platform, quests or just want to have a good time then this game is for you. Features of this game: High Quality graphics will leave only positive feedback Game size is minimal (but not compromised the graphics) and it is good for your device health Super simple controls make it easy to start playing Interesting quests will not let you get bored while playing the game",
            "instructions": "Android Use On-Screen touch buttons to control the player nbsp PC Left Arrow - To move Left Right Arrow - To move Right Up Arrow - To Jump",
            "url": "https://html5.gamemonetize.com/6mw96kdp3ketqjyafnad8t2099h4z9tv/",
            "category": "Hypercasual",
            "tags": "2D, Adventure, Casual, Platform, survival",
            "thumb": "https://img.gamemonetize.com/6mw96kdp3ketqjyafnad8t2099h4z9tv/512x384.jpg",
            "width": "480",
            "height": "270"
          },
          {
            "id": "32633",
            "title": "Sweet Solve",
            "description": "Collect strawberry to the bucket using your brain This game consists of a challenge to collect strawberries in each level by solving the way to get it. Physics based game where you have to destroy blocks and make the way or progress so that strawberries can be collected easily. Think carefully before taking any steps. Either dropping strawberries or destroying them will be the game over. Don&amp;rsquo;t forget to collect stars. If you like genres such as puzzles, physics based games or just want to have a good time then this game is for you. Features of this game: High Quality graphics Game size is minimal Super simple controls Interesting Quests",
            "instructions": "How to play Android Use On-Screen touch or tap PC Use Mouse Left Click ",
            "url": "https://html5.gamemonetize.com/leiasg9oe4sc50inmnmdp4lg328pc5f5/",
            "category": "Puzzles",
            "tags": "2D, Casual, Fun, Puzzle",
            "thumb": "https://img.gamemonetize.com/leiasg9oe4sc50inmnmdp4lg328pc5f5/512x384.jpg",
            "width": "640",
            "height": "960"
          },
          {
            "id": "32624",
            "title": "Nut And Bolt",
            "description": "Get ready to challenge your mind with this fun and addictive puzzle game! Your goal is to match bolts of the same color to the correct nut. It sounds simple, but each level gets trickier as you go, requiring more focus and strategy to complete. As you progress, the levels become more challenging with different colors of bolts.",
            "instructions": "Your task is to match bolts with the same color and place them into the nuts Each bolt is color-coded and you need to carefully arrange them inside the nuts Once all the same-colored bolts are set you rsquo ll complete the puzzle If you find it diffi",
            "url": "https://html5.gamemonetize.com/2fcl63xms01b6w2jai0osfpkrpykl70l/",
            "category": "Puzzles",
            "tags": ".io Games, Number, Puzzle",
            "thumb": "https://img.gamemonetize.com/2fcl63xms01b6w2jai0osfpkrpykl70l/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32623",
            "title": "Stickman Night Survive",
            "description": "Now that Halloween is here, the gloomiest day of the year, its time to bring back the courageous white stickman who is an expert at vanquishing monsters. Stop procrastinating and start enjoying Stickman Night Survive right now on your preferred mobile device. Its a fun and free online game. Every living thing on Earth is frightened to death when monsters emerge from their underground lairs on Halloween night.",
            "instructions": "Instructions Help the stickman aim his weapon at the right time to shoot the monsters located on different mobile platforms Controls Use the mouse to aim and shoot or touch and drag on mobile screens ",
            "url": "https://html5.gamemonetize.com/zx1x0yknh5emq3mzop2fvbpimsxgpz9h/",
            "category": "Hypercasual",
            "tags": "Halloween, kiz10, Shooting, Stickman, survival",
            "thumb": "https://img.gamemonetize.com/zx1x0yknh5emq3mzop2fvbpimsxgpz9h/512x384.jpg",
            "width": "900",
            "height": "600"
          },
          {
            "id": "32622",
            "title": "BFFs Golden Hour",
            "description": "Get ready to conquer the fashion world with Fall Aesthetics! Travel the globe alongside our stylish crew and unlock your inner fashionista. Mix and match outfits inspired by the vibrant streets of New York, the golden sunsets of Tuscany, the mesmerizing festivals, the traditional beauty of Japan, and the enchanting charm of Scotland. With endless options for dressing up and a touch of wit and fun, Fall Aesthetics will keep you entertained for hours. Play now and let your fashion sense shine like never before!",
            "instructions": "Use your mouse to play the game on a desktop or tap to play on mobile devices ",
            "url": "https://html5.gamemonetize.com/66m25m8w23jo0y0jrhb5g88188suqpwd/",
            "category": "Girls",
            "tags": "1 Player, Dress Up, Fashion, Girls, html5 games, Princess",
            "thumb": "https://img.gamemonetize.com/66m25m8w23jo0y0jrhb5g88188suqpwd/512x384.jpg",
            "width": "1024",
            "height": "768"
          },
          {
            "id": "32620",
            "title": "Fidget Trading Card Toy",
            "description": "Fidget Trading Card Toy is a fidget trading game that allows you to feel the fun of collecting toy cards! In the game, you will flip cards against opponents and even use the slap feature to set off a fierce trading duel! Your goal is to collect as many rare cards as possible, increase your card collection, and gain more advantages in trading!",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/7zoawxqu9m4lg1d02ew9z3t7bmktj5bw/",
            "category": "Hypercasual",
            "tags": "Battle, Card",
            "thumb": "https://img.gamemonetize.com/7zoawxqu9m4lg1d02ew9z3t7bmktj5bw/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32619",
            "title": "Hungry Crab",
            "description": "In &amp;ldquo;Hungry Crab&amp;rdquo; your objective is to guide the candy to the crab&amp;rsquo;s mouth by strategically cutting ropes while collecting the stars. Simply swipe your finger across the screen to cut the ropes to play. The candy will then be released and begin its descent toward the crab. However, you must be careful not to let the candy flow away. Features of this game: High Quality graphics will leave only positive feedback Game size is minimal (but not compromised the graphics) and it is good for your device health Super simple controls make it easy to start playing Interesting quests will not let you get bored while playing the game.",
            "instructions": "How to play Android Use On-Screen touch and cut the rope PC Use mouse Left click and cut the rope ",
            "url": "https://html5.gamemonetize.com/sme8rz5qvjf5if0ss3nbztssufmikaoj/",
            "category": "Puzzles",
            "tags": "2D, Casual, Fun, Puzzle",
            "thumb": "https://img.gamemonetize.com/sme8rz5qvjf5if0ss3nbztssufmikaoj/512x384.jpg",
            "width": "1080",
            "height": "1920"
          },
          {
            "id": "32607",
            "title": "Percys Froggy Frenzy",
            "description": "Jump into the vibrant world of Percys Froggy Frenzy! Guide Percy, a daring frog, through a non-stop adventure where he shoots bubbles. Move Percy left and right as he auto-fires bouncing shots to pop falling bubbles. Along the way, jump across dynamic platforming levels to gather even more coins, boosting Percy&amp;rsquo;s shooting speed, power, and health. Beware&amp;mdash;if Percy takes hits, maybe you&amp;rsquo;ll need to restart the level. With endless progression and varied challenges, this game is perfect for fans of fast-paced, action-packed platformers!",
            "instructions": "Keys A and D or arrows",
            "url": "https://html5.gamemonetize.com/1ye2ka2gz2m1wsy539bi6q5ev2eaqd96/",
            "category": "Hypercasual",
            "tags": "Bubble, Casual, Collecting, frog, Platform",
            "thumb": "https://img.gamemonetize.com/1ye2ka2gz2m1wsy539bi6q5ev2eaqd96/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32606",
            "title": "Among Knights",
            "description": "Among Knights is an Adventure Arcade 2D Game that tells a wonderful story of an ancient war between Knights and mutants. Among Knights comes with easy and enjoyable gameplay in an amazing 2D environment, with several places following the story. the games story tells the destiny of a young Knight who was fated to revenge the death of his father and finds out some truth about the ancident. Dive into the story of Among Knights, with the young knight, and defeat some of those mutants in pursuit of their leaders who called the Giant Mutant! Theres plenty to find, but one misstep and its all over.",
            "instructions": "PC WQD For Movement amp Jump Mouse To Attack and Special attack F for Firing Mobile Virtual Joystick for Movement Touch Keys for Attack Special Attack Jump Fire",
            "url": "https://html5.gamemonetize.com/7rqz6ji1okgid9e6zqt6jq7bc6wt3e2s/",
            "category": "Arcade",
            "tags": "2D, Best Games, Casual, Knight, Sword",
            "thumb": "https://img.gamemonetize.com/7rqz6ji1okgid9e6zqt6jq7bc6wt3e2s/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32602",
            "title": "Heli Monsters Giant Hunter",
            "description": "Ready to face the massive challenge? In Heli Monsters - Giant Hunter, you will face crazy monsters and begin an exciting shooting adventure! You will use your gun to aim at the monster and fight them. Your task is to destroy them and keep the city safe! Buy higher-damage guns and bullets with gold. Such as AWM, M200, M24, etc.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/tloh1r8i3pywjg2n3wyemj2uqjlktcnj/",
            "category": "Arcade",
            "tags": "Gun, Shoot, Spiderman",
            "thumb": "https://img.gamemonetize.com/tloh1r8i3pywjg2n3wyemj2uqjlktcnj/512x384.jpg",
            "width": "750",
            "height": "1334"
          },
          {
            "id": "32595",
            "title": "Ping Pong Battle Table Tennis",
            "description": "Want to have an intense table tennis battle? In Ping Pong Battle - Table Tennis, you will become a ping pong pro and compete with your opponents in a fierce competition! This game combines realistic paddle manipulation with exciting match simulation.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/80whi1rzhhbrxn1f4le2ll0cf5vx5wv2/",
            "category": "Arcade",
            "tags": "Sport, Tennis",
            "thumb": "https://img.gamemonetize.com/80whi1rzhhbrxn1f4le2ll0cf5vx5wv2/512x384.jpg",
            "width": "750",
            "height": "1334"
          },
          {
            "id": "32587",
            "title": "Light Stream",
            "description": "Pass through the laser beam by reflecting in the mirror to unlock the gate. At the beginning we see a boy, who reveals how the door will be unlocked by Laser beam to reach the next level. Now its the players turn to accept the challenge and solve the door unlock process by setting the laser beam to the correct position. Don&amp;rsquo;t forget to collect 3 stars. If you like genres such as physics based games, laser beam games or just want to have a good time then this game is for you. Features of this game: High Quality graphics Game size is minimal Super simple controls Interesting Quests",
            "instructions": "How to play Android Use On-Screen touch to control the mirror direction PC Use Mouse Left Click to control the mirror direction ",
            "url": "https://html5.gamemonetize.com/t2g2zoolw99aaoy8seep0ebtuk56phv9/",
            "category": "Puzzles",
            "tags": "2D, Casual, Puzzle",
            "thumb": "https://img.gamemonetize.com/t2g2zoolw99aaoy8seep0ebtuk56phv9/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32573",
            "title": "Coin Sort Puzzle",
            "description": "Play now for free&amp;mdash;fun and addictive grid gameplay. Sort and merge coins to clear the board. Test your skills and earn coins. Fun for all ages! Welcome to the exhilarating world of Coin Sort, where speed, precision, and strategy come together in a captivating arcade-style puzzle game. As a Coin Sorter in a match game, your mission is to efficiently organize an ever-growing flow of coins into their respective lines based on their denominations. Are you ready to put your decision-making skills and reflexes to the test? Embark on a thrilling sorting adventure and unlock new levels.",
            "instructions": "Coin Sort and Merge offers a thrilling blend of strategy reflexes and puzzle-solving that will keep you attracted for hours This addictive game provides endless fun and entertainment Get ready to immerse yourself in the world of coins sorting and me",
            "url": "https://html5.gamemonetize.com/imaqq34l0iw90484l470rs0cfu7pofze/",
            "category": "Puzzles",
            "tags": "1 Player, 2048, Puzzle, sort",
            "thumb": "https://img.gamemonetize.com/imaqq34l0iw90484l470rs0cfu7pofze/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32571",
            "title": "Bus Jam Sort",
            "description": "The passengers are waiting for their bus, sort them out based on their color! Visually, Bus Jam Game is a visual treat, imagine colorful buses, a diverse passengers, and a lively backdrop setting the stage for your bustling triple match puzzle adventure. Smooth animations elevate the fun, making each move a delightful experience in puzzle games.",
            "instructions": "So prepare yourself for the challenge Can you master the sort puzzle shuffle passengers and release VIPs in the on time With its simple yet clever bus match gameplay this game guarantees hours of entertainment for those who love the thrilled puzzle ",
            "url": "https://html5.gamemonetize.com/wlb9vj2y6oazlm6q8vvostlu8w649f71/",
            "category": "Puzzles",
            "tags": "1 Player, bus, puzzleblock, sort",
            "thumb": "https://img.gamemonetize.com/wlb9vj2y6oazlm6q8vvostlu8w649f71/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32557",
            "title": "Offroad Driving Truck Transport",
            "description": "Offroad Driving Truck Transport is a thrilling adventure where you navigate rugged terrains, muddy paths, and steep hills. Take control of powerful trucks and transport heavy cargo across challenging off-road landscapes. Master your driving skills, overcome obstacles, and feel the adrenaline rush in this ultimate off-road trucking experience! Web Dev &lt;a href='https://www.crazygamesonline.com/'&gt;https://www.crazygamesonline.com/&lt;/a&gt;",
            "instructions": "Use WASD to controll your truck",
            "url": "https://html5.gamemonetize.com/4bk844pemos2hamkblsoxh01dzxbqm3h/",
            "category": "Action",
            "tags": "Driving, Forest, Kids, Levels, Mentolatux, offroad, Simulator, Truck, trucks, Unity3D, vehicle, WebGL",
            "thumb": "https://img.gamemonetize.com/4bk844pemos2hamkblsoxh01dzxbqm3h/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32556",
            "title": "Halloween Merge",
            "description": "Merge spooky items, unlock haunted treasures, and celebrate Halloween in this addictive puzzle game. Get ready for a spooky adventure with Halloween Merge: Haunted Puzzle! Dive into a mysterious world filled with Halloween-themed puzzles, spooky items, and eerie challenges that will test your merging skills. Combine haunted artifacts like pumpkins, potions, and ghostly decorations to unlock chilling treasures and transform your haunted mansion. Enjoy a unique merge game experience with Halloween vibes around every corner. Match and merge items to complete levels, reveal secrets, and win daily rewards!",
            "instructions": " Desktop Select and drag items to merge them Click on an item hold down the left mouse button and drag it to the target item to merge Mobile Tap and hold on an item to drag it and drop it onto another to merge ",
            "url": "https://html5.gamemonetize.com/7hd69nd6fkv70epx1d4kwjkx2ymxd3yk/",
            "category": "Puzzles",
            "tags": "Arcade, Best Games, Fun, Halloween, HTML, html5 games, NapTech Games, Puzzle",
            "thumb": "https://img.gamemonetize.com/7hd69nd6fkv70epx1d4kwjkx2ymxd3yk/512x384.jpg",
            "width": "1080",
            "height": "1920"
          },
          {
            "id": "32543",
            "title": "Plane Crash Ragdoll Simulator",
            "description": "As a fearless pilot, your mission is simple: survive a relentless onslaught of rockets targeting your aircraft. But dont be fooled by how easy it looks. Youll feel the rush of adrenaline as you fire rockets at your enemies and fly through dangerous skies! Features: - Many Game Modes: Avoid Rockets, Destroy Enemies and Low Fuel Landing! - Variety of Aircraft: from planes to helicopters - Customizable Skins: unlock various skins for character and aircraft - Realistic Physics &amp;amp; Ragdoll Pilot! - Challenging Levels and Scenarios",
            "instructions": "Aircraft Gas Press the Joystick Aircraft Control Move the Joystick",
            "url": "https://html5.gamemonetize.com/9vz6x0b5dmyk48s8nalz74iupnzh3tyq/",
            "category": "Action",
            "tags": "3D, Action, Air, Aircraft, airplane, Casual, endless, fly, Mobile, Physics, Simulation, Simulator, unity, War",
            "thumb": "https://img.gamemonetize.com/9vz6x0b5dmyk48s8nalz74iupnzh3tyq/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32541",
            "title": "Transport Army vehicle truck driving",
            "description": "Transport vital supplies across rugged terrain, navigating through enemy territory and dangerous landscapes. Complete challenging missions, dodge roadblocks, and upgrade your vehicles armor and speed to overcome obstacles. With realistic controls, customizable trucks, and immersive levels, only the best drivers will lead their convoy to success. Prepare to rev up, strategize, and master the art of military transport in the ultimate army truck driving experience! Web Dev &lt;a href='https://www.insanegamesonline.com/'&gt;https://www.insanegamesonline.com/&lt;/a&gt;",
            "instructions": "WASD to drive the vehicle",
            "url": "https://html5.gamemonetize.com/bi51p6ugftg8ngxfz4x18hw50jskk0fy/",
            "category": "Action",
            "tags": "Army, Driving, Mentolatux, offroad, transport, Truck, trucks, Unity3D, vehicle, WebGL",
            "thumb": "https://img.gamemonetize.com/bi51p6ugftg8ngxfz4x18hw50jskk0fy/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32539",
            "title": "Basketball Street",
            "description": "Select your favourite player among the 3 available and show your free throws skills! You have one minute and 25 shots to get as many balls as possible into the basket! Striped balls are worth 2 points (instead of 1): concentrate on these special balls for extra points!",
            "instructions": "Use Mouse To Play and Keyboard",
            "url": "https://html5.gamemonetize.com/xvwtddwzkqcwdcahhsrs5btoza8s218i/",
            "category": "Sports",
            "tags": "Arcade, Ball, Ballon, Basketball, Sport",
            "thumb": "https://img.gamemonetize.com/xvwtddwzkqcwdcahhsrs5btoza8s218i/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32529",
            "title": "Emoji Skill Puzzles",
            "description": "Emoji Skill Puzzles is an engaging skill game. Featuring levels constructed entirely with popular emojis. The game offers six distinct puzzle types: link puzzle, memory puzzle, word quiz, related puzzle, shadow puzzle, and crop puzzle. This game is perfect for players who enjoy brain teasers and want to test their problem-solving skills.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/grcoj83culvapsxit0uaznoyk1yqf6a0/",
            "category": "Puzzles",
            "tags": "Best, Educational, Fun, Funny, Kids, Matching, Memory, Mobile, Skill",
            "thumb": "https://img.gamemonetize.com/grcoj83culvapsxit0uaznoyk1yqf6a0/512x384.jpg",
            "width": "640",
            "height": "960"
          },
          {
            "id": "32522",
            "title": "Blocks: Move HIT   PRO",
            "description": "Calling all blockheads and those who are called brainy! In Blocks - Move &amp;amp; HIT -PRO youre a hero on a mission, but the path is ever-changing. Navigate a maze of immovable stone blocks that bounce you off course with every bump. Will you hit a 45-degree angle and zip diagonally, or take a full 90-degree turn? Master the rebounds, solve the shifting puzzle, and guide your block to its destined end!",
            "instructions": "Find a path and hit the target to win Challenging ",
            "url": "https://html5.gamemonetize.com/qxlzvjrlxn6clye52zlgntdyjb3ho7w2/",
            "category": "Puzzles",
            "tags": "blocky, Brain, Puzzle, puzzleblock",
            "thumb": "https://img.gamemonetize.com/qxlzvjrlxn6clye52zlgntdyjb3ho7w2/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32519",
            "title": "Truck Driving Construction Transport",
            "description": "The player takes on the role of a skilled truck driver in the construction transport industry. The objective is to navigate various terrains, loading essential construction materials and equipment onto the truck, then driving carefully to the designated construction site. Challenges include maneuvering through traffic, avoiding hazards, and meeting strict delivery times. Web Dev &lt;a href='https://www.crazygamesonline.com/'&gt;https://www.crazygamesonline.com/&lt;/a&gt;",
            "instructions": "WASD to drive your truck",
            "url": "https://html5.gamemonetize.com/fhqjmji6kcucsvwoaplxbtfxoqpya1pz/",
            "category": "Action",
            "tags": "construction, Driving, Kids, Mentolatux, Simulator, Truck, trucks, vehicle",
            "thumb": "https://img.gamemonetize.com/fhqjmji6kcucsvwoaplxbtfxoqpya1pz/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32506",
            "title": "Cubble on top",
            "description": "cubble on top is a very unique jumping game, you have to jump onto the cliff walls to get to the top and get to the finish, your patience will be tested in this game",
            "instructions": "drag and drop top jump",
            "url": "https://html5.gamemonetize.com/mcu1qexvqwksgoby0bw05y01hqzxlfsu/",
            "category": "Hypercasual",
            "tags": "1 Player, Arcade, jump",
            "thumb": "https://img.gamemonetize.com/mcu1qexvqwksgoby0bw05y01hqzxlfsu/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32502",
            "title": "Point to Merge",
            "description": "Point to Merge is an engaging puzzle game where player must strategically connect numbered blocks to reach the highest value possible. Starting with two blocks numbered 2, the player must point arrows to direct each block towards its match, merging numbers in a sequence (2, 4, 8, 16, etc.). Precision is key&amp;mdash;one wrong move or pointing outside the level restarts the puzzle! With 50 progressively challenging levels, Point to Merge combines sharp thinking and skillful direction to master the art of merging. Can you reach the final number?",
            "instructions": "Point and merge ",
            "url": "https://html5.gamemonetize.com/szb7rfuwk4wnsktxfcpoa49d7r1ircbq/",
            "category": "Hypercasual",
            "tags": "2048, Color, Geometry, Hypercasual",
            "thumb": "https://img.gamemonetize.com/szb7rfuwk4wnsktxfcpoa49d7r1ircbq/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32499",
            "title": "Football Leauge",
            "description": "Step into the thrill of the stadium with Football League, a fast-paced, action-packed soccer game where you lead your team to victory! Choose your players, master your tactics, and score incredible goals to climb the ranks and become the champion. With intuitive controls, stunning graphics, and dynamic gameplay, Football League delivers an immersive soccer experience for players of all skill levels.",
            "instructions": "Desktop Tap the right buttons of the mouse to throw the ball to other player Mobile Tap the screen to throw the ball to other player ",
            "url": "https://html5.gamemonetize.com/qahsfosbapnzdo3ya16hjf9uudzw7eob/",
            "category": "Sports",
            "tags": "1 Player, Football, NapTech Games, Sport, Sports",
            "thumb": "https://img.gamemonetize.com/qahsfosbapnzdo3ya16hjf9uudzw7eob/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32492",
            "title": "Match 3D Puzzle Mania",
            "description": "Enter the world of match-3 game brought to life in 3D. Solve captivating puzzles and collect coins to acquire power-ups. Complete daily tasks to earn fantastic bonuses and experience endless fun with every move you make!",
            "instructions": "The game features limitless levels Players match objects to clear levels and progress Each level has three difficulties and four unique abilities that help overcome challenges Daily quests and rotating events provide opportunities for additional reward",
            "url": "https://html5.gamemonetize.com/dknfzlo018fucv5igyifvwuiprwrhtxy/",
            "category": "Puzzles",
            "tags": "3D, Casual, Color, Coloring, Logic, Match 3, Match3, Puzzle",
            "thumb": "https://img.gamemonetize.com/dknfzlo018fucv5igyifvwuiprwrhtxy/512x384.jpg",
            "width": "600",
            "height": "800"
          },
          {
            "id": "32483",
            "title": "Lift The Girl",
            "description": "Lift The Girl is a free online adventure game for kids, which with rich levels, 2D perspective, fresh style, cartoon skin, and a random combination of level challenges that will surely open your eyes. Different levels have different traps, do not let the little sister hurt, or you will have to start all over again. Interested friends come to experience it!",
            "instructions": "Use the Mobile Left Click Mouse Button To Drag and Drop the weights Click On The Character To Move",
            "url": "https://html5.gamemonetize.com/dbm8az5wd2ein808z0u8x3lgqp6rt3h8/",
            "category": "Puzzles",
            "tags": "2D, amazing, Boy, Fun, Girl, Puzzle",
            "thumb": "https://img.gamemonetize.com/dbm8az5wd2ein808z0u8x3lgqp6rt3h8/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32476",
            "title": "Push The Frog",
            "description": "Control your little frog to eat all the mosquitoes. Master the length of the frogs tongue and make the frog reach the appropriate position to eat the mosquitoes. Think carefully about planning the route. The game has a simple and interesting style, and the gameplay is educational and novel. It is very suitable for playing when killing time.",
            "instructions": "WASD or arrows to move Spacebar pops out the frogs tongue",
            "url": "https://html5.gamemonetize.com/cd4hjkqwg8pgx61kolqxllt3x4rj7v1j/",
            "category": "Puzzles",
            "tags": "Animal, Brain, H5, Kids, Levels, Logic, Logical, Online",
            "thumb": "https://img.gamemonetize.com/cd4hjkqwg8pgx61kolqxllt3x4rj7v1j/512x384.jpg",
            "width": "750",
            "height": "1334"
          },
          {
            "id": "32467",
            "title": "Rolling Ball Halloween Escape",
            "description": "Want to have a terrifying adventure in Rolling Ball Halloween scape? This exciting game invites you to roll through a scary world. Here is full of many vivid Halloween decorations! Roll your ball through the eerie sky, avoid obstacles, and escape the haunted landscape! Control your ball,speeding it up or backing it through many obstacles.Pay attention to avoiding swinging pendulums,shattering boxes and so on.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/govh3xheo7xcct4h7lkbiu5ka4ts1b3h/",
            "category": "Arcade",
            "tags": "Ball, Escape, Halloween",
            "thumb": "https://img.gamemonetize.com/govh3xheo7xcct4h7lkbiu5ka4ts1b3h/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32458",
            "title": "Launch Jack",
            "description": "Launch Jack is an exciting physics-based game where you catapult pumpkins to destroy zombie heads and save Halloween night! As Jack, the fearless pumpkin, you&amp;rsquo;ll launch yourself through spooky, haunted landscapes, aiming to eliminate all zombie heads standing in your way. Strategize your shots, break through tricky obstacles, and unleash powerful combos to clear each level. With increasing challenges, eerie settings, and special power-ups, your goal is simple: destroy all the zombie heads and rescue Halloween from disaster!",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/bm57pfcx9wun729i5z0nl4xfelok6g88/",
            "category": "Puzzles",
            "tags": "Family, Halloween, mapi games, Puzzle",
            "thumb": "https://img.gamemonetize.com/bm57pfcx9wun729i5z0nl4xfelok6g88/512x384.jpg",
            "width": "1024",
            "height": "600"
          },
          {
            "id": "32457",
            "title": "Stick Archer Champion",
            "description": "Defeat other stick figures and show the world whos the finest archer in Stick Archer Champion, an action game. Delve into the realm of stick figures and unearth their secret meanings. Arm yourself with better bows, arrows, quivers, and shields. Learn new talents and unlock new effects to unlock more arrows. Add personality to your stickman by letting him wear a variety of caps, masks, hairdos, beards, and animations. Add more stickers to the stickman flag collection.",
            "instructions": "Instructions Move from side to side to better aim at your enemy and avoid enemy arrows You can collect many coins with each completed level and with those coins improve your arrows unlock new skin and powers Controls Mouse click to select Keys A D a",
            "url": "https://html5.gamemonetize.com/sau8efctplff566hb06hzx5jyhgu6xyd/",
            "category": "Fighting",
            "tags": "arrows, kiz10, Shooting, Stickman",
            "thumb": "https://img.gamemonetize.com/sau8efctplff566hb06hzx5jyhgu6xyd/512x384.jpg",
            "width": "900",
            "height": "600"
          },
          {
            "id": "32450",
            "title": "Black Sphere",
            "description": "In front of you on the screen you will see a playing field on which you will see several round cells. One of them will contain a white ball. One of the cells will be highlighted in a specific color. A black ball will appear at the top of the playing field. You can use the mouse to move it to one of the cells. This must be done in such a way that the black ball then pushes the white one, and it ends up in the selected cell. If you manage to do this, then you will be given points in the Black Sphere game and you will move to the next level of the game.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/zzmd60946ofjq4eyfq9dmdjyaqrkd8pt/",
            "category": "Puzzles",
            "tags": "Best, Brain, Fun, Kids, Logic, Logical, Mobile, Skill",
            "thumb": "https://img.gamemonetize.com/zzmd60946ofjq4eyfq9dmdjyaqrkd8pt/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32428",
            "title": "Mechangelion Robot Fight",
            "description": "Want to fight robots in a mechanical arena? Mechangelion: Robot Fight is a 3D robot action game full of blood. Let you incarnate as a powerful mechanical warrior, and set foot on the peak of the fight! You will as a super robot and engage in fierce battles with enemies.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/1n7eufky9udoq7ran8na3nc3n4jv3000/",
            "category": "Arcade",
            "tags": "attack, Fight, Robot",
            "thumb": "https://img.gamemonetize.com/1n7eufky9udoq7ran8na3nc3n4jv3000/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32413",
            "title": "321 Diferent Patch",
            "description": "321 Diferent Patch is a casual, quick-play game in which you have to discover the different figure among several that look the same. You have three seconds to find the different figure. Look at every detail, that can make the difference. The difficulty increases with every step. And be careful, the figure that is wrong once, may be the right one the next time. Do you want to play quietly? Relax mode is for you. Just like Normal mode, but with plenty of time to examine the figures. And if youre ready, try the Extreme mode.",
            "instructions": "Mouse click or tap ",
            "url": "https://html5.gamemonetize.com/j3a9bdajchqcaca8hztgb37a89baazdo/",
            "category": "Puzzles",
            "tags": "Casual, Difference, Mind, Skill, Skills",
            "thumb": "https://img.gamemonetize.com/j3a9bdajchqcaca8hztgb37a89baazdo/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32408",
            "title": "Princesses at Horror School",
            "description": "In this game, you will meet Laura and Frankie, two charming monsters who study at a school for young monsters. Every morning, the girls carefully think over their images, mixing elements of a classic princess and a creepy monster. Each of the girls has their own special fashion preferences, but when going to class, Laura and Frankie always attract the admiring glances of classmates. After all, they are not just princesses, but real monsters whose terrifying style makes even the bravest tremble!",
            "instructions": "Depending on the gaming device a computer mouse click or a simple touch on touch screens is used to control it The result can be saved as a PNG image ",
            "url": "https://html5.gamemonetize.com/dtfziaj2zz4xglbzcdctg1uqyttpkj3y/",
            "category": "Girls",
            "tags": "Dress Up, Hair, Halloween, Makeover, Monster, School",
            "thumb": "https://img.gamemonetize.com/dtfziaj2zz4xglbzcdctg1uqyttpkj3y/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32402",
            "title": " Kitchen Sorting",
            "description": "Kitchen Sorting uses well known meachanics of ball and water sorting games. Sort cooking ingredients into glass jars right in your comfortable kitchen. When sorted, pour them into the pot a cook meals.",
            "instructions": "Tap jar to pick one or more ingredients of the same kind from the top Tap another jar to drop them in You can drop them only on top of the same kind of ingredients or into an empty jar You can use swipe from source jar to destination one instead of tap",
            "url": "https://html5.gamemonetize.com/qt9jxy4clkxrv1zharuv23dr2vl4ohhe/",
            "category": "Puzzles",
            "tags": "Ball, Cook, Cooking, Food, Logic, Puzzle, sorting, water",
            "thumb": "https://img.gamemonetize.com/qt9jxy4clkxrv1zharuv23dr2vl4ohhe/512x384.jpg",
            "width": "600",
            "height": "800"
          },
          {
            "id": "32400",
            "title": "Sawblade Fest Run",
            "description": "Like cutting things into many pieces? Challenge your reflexes in Sawblade Fest Run! In this intense arcade game, your goal is very simple. Keep running, avoiding deadly obstacles like iron sheets, walls, and other giant fruit monsters!",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/jwrsy3u3pe26a9duc6houu4ouc0bsad5/",
            "category": "Racing",
            "tags": "Arcade, Fruits, Jigsaw",
            "thumb": "https://img.gamemonetize.com/jwrsy3u3pe26a9duc6houu4ouc0bsad5/512x384.jpg",
            "width": "576",
            "height": "1024"
          },
          {
            "id": "32392",
            "title": "Cooking Fever Happy Chef",
            "description": "Are you ready to make your mark on Cooking Fever: Happy Chef? This interesting simulation lets you feel the fun of cooking, serving and managing your restaurant! With colorful graphics and special gameplay, put yourself in the food world and try to become the ultimate chef!",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/0nfy14ng1ltqdxupj8vumbrqrz8kcnur/",
            "category": "Hypercasual",
            "tags": "Food, Restaurant, Simulation",
            "thumb": "https://img.gamemonetize.com/0nfy14ng1ltqdxupj8vumbrqrz8kcnur/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32380",
            "title": "Find The  Correct Shadow",
            "description": "Find The Correct Shadow is a fun and educational puzzle game designed to challenge your visual skills! In each round, youll be presented with a picture and three shadow options. Only one of the shadows matches the picture perfectly&amp;mdash;can you find the correct one? With three exciting game modes to choose from: Streak Mode: Test how long you can maintain a perfect streak of correct answers! Timed Mode: Race against the clock and see how many correct shadows you can find in a limited time! Learning Mode: A relaxed mode designed to help you practice and improve your visual recognition skills.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/3m043n1cb8et8w63mdahpdqao96trr6u/",
            "category": "Puzzles",
            "tags": "Difference, Educational",
            "thumb": "https://img.gamemonetize.com/3m043n1cb8et8w63mdahpdqao96trr6u/512x384.jpg",
            "width": "560",
            "height": "854"
          },
          {
            "id": "32364",
            "title": "Good Slice",
            "description": "Come to start a juicy cut fruit journey at Good Slice! This is a casual puzzle game that will test your cutting skills. Your task is to swipe the screen to cut the fruit into perfect pieces and let them fall smoothly into the juicer. The more precise the cut, the more juice youll get!",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/1r1xtgybfs46lrr9r5rntmn5ww8vlb63/",
            "category": "Puzzles",
            "tags": "cut, Fruits",
            "thumb": "https://img.gamemonetize.com/1r1xtgybfs46lrr9r5rntmn5ww8vlb63/512x384.jpg",
            "width": "750",
            "height": "1334"
          },
          {
            "id": "32363",
            "title": "Daily Bento Organizer",
            "description": "Do you want to be a bento box master? Using your creativity in the world of the Daily Bento Organizer. To create the most delicious bento box in this casual game. There is the art of food arrangement and the storage management challenge.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/5q2hi88nygzibgvsyrduea8u5zuk60zr/",
            "category": "Hypercasual",
            "tags": "Collection, Puzzle",
            "thumb": "https://img.gamemonetize.com/5q2hi88nygzibgvsyrduea8u5zuk60zr/512x384.jpg",
            "width": "1080",
            "height": "1920"
          },
          {
            "id": "32351",
            "title": "Cut Cut Game",
            "description": "The rabbit Dorote, owner of an orchard, was preparing for an event in her village. When inspecting his oranges, he noticed that some of them were hiding explosive surprises, left by someone. However, she was in a hurry and couldnt handle it now, as she needed to prepare the juice for the event. Help her cut the oranges carefully, avoid cutting the wrong things!",
            "instructions": "Tap and slide with your FINGER or use YOUR MOUSE to cut the oranges",
            "url": "https://html5.gamemonetize.com/y610ae7x8dvdis9ertudfnqkxbv810v5/",
            "category": "Hypercasual",
            "tags": "1 Player, 2D, Android, Arcade, Best Games, Bomb, Click, cut, eggs, Rabbit, Samurai, Sword, touch, touchscreen",
            "thumb": "https://img.gamemonetize.com/y610ae7x8dvdis9ertudfnqkxbv810v5/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32341",
            "title": "Do not enter this game at night",
            "description": "Do not enter this game at night, because in this game you will find the most creepy screamers during the passage of the maze, playing for the ball. But if you decide to go in, then you will find a lot of levels, puzzles and, of course, screamers.",
            "instructions": "P Keys W A S D - Move Smartphones A joystick on the screen from the bottom left ",
            "url": "https://html5.gamemonetize.com/tpfvbgcyqy5wzsklzb9bykqldr4ut6hw/",
            "category": "Puzzles",
            "tags": "1 Player, 3D, 3D Games, Adventure, amazing, Ball, Horror, Mobile, New, unity, unity games, unity games for your site, unity games for your website, Unity3D",
            "thumb": "https://img.gamemonetize.com/tpfvbgcyqy5wzsklzb9bykqldr4ut6hw/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32340",
            "title": "Warfare Area 3",
            "description": "Play 3D first person shooter game Warfare Area 3. Your mission is to explore the enemy base and take down all the guards in the area. Shoot enemies, pick up first aid kits and earn money to upgrade the armor and guns.",
            "instructions": "Computer Arrow keys or W A S D to move the character Mouse to turn the character aim and shoot L to lock pointer R to reload P - pause Mobile Use on-screen joystick to move fire button to shoot tap and drag on right side of the screen to aim and",
            "url": "https://html5.gamemonetize.com/9xa5tfoih6vphdexzk034fl8ycjwzffz/",
            "category": "Shooting",
            "tags": "3D, 3D Games, Action, Android, Army, Boys, Bullet, Enemy, fps, Gun, HTML5, iPhone, Killing, military",
            "thumb": "https://img.gamemonetize.com/9xa5tfoih6vphdexzk034fl8ycjwzffz/512x384.jpg",
            "width": "900",
            "height": "506"
          },
          {
            "id": "32339",
            "title": "Draw Save Puzzle",
            "description": "Draw Save Puzzle - draw one line to save Stickman from the threat! Are you a good artist or Do you want to test your creative skills? Are you a fan of puzzles? Now you have a good chance! Draw a line to help the little man and protect him from swords, bullets, bombs.... and many other life-threatening attacks! You are free to draw any cover and protection to help the little man survive. Learn to draw lines creatively, develop logic and grow your brain! GAME FEATURES + Lots of levels and endless fun! + Never be bored again! + Simple but amazing physics system! + Improve your brain! + Exciting and relaxing gameplay.",
            "instructions": " Draw only one continuous line to complete the level task Make sure your line does not damage the Stickman you need to protect There can be more than one answer Draw any thing you want as each one can be a solution to the challenge ",
            "url": "https://html5.gamemonetize.com/76kn0zqb9ti95c31997em839qaaq0bj2/",
            "category": "Fighting",
            "tags": "1 Player, 2D, Arcade, Best Games, Bird, Brain, Casual, Game, Games, Puzzle, Stickman",
            "thumb": "https://img.gamemonetize.com/76kn0zqb9ti95c31997em839qaaq0bj2/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32335",
            "title": "Zombiracer Speed On Earth",
            "description": "Ready to face the zombie apocalypse challenge? In Zombiracer: Speed On Earth, you will enter an exciting track. Merge powerful vehicles, and fight zombies to the death! In this exciting racing game, you can boost your combat power by merging the same vehicle parts. Each vehicle has special attributes and abilities.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/594nplusw7po4zpb6uqgcbi6bdpdb5vh/",
            "category": "Racing",
            "tags": "Superhero, tractor, vehicle",
            "thumb": "https://img.gamemonetize.com/594nplusw7po4zpb6uqgcbi6bdpdb5vh/512x384.jpg",
            "width": "1664",
            "height": "750"
          },
          {
            "id": "32325",
            "title": "Math Driving Test",
            "description": "Drive and grab the objects to get the requested amount of unit cubes as quickly as possible. You have to get a medal to pass each level. Hitting tree or building or fence 3 times fails the game. Use the arrow keys I WASD or arrow buttons (mobile) to accelarate and steer the car.",
            "instructions": "WASD arrow buttons",
            "url": "https://html5.gamemonetize.com/souihl4o9tpdd7xadmz778waakj6m29m/",
            "category": "Sports",
            "tags": "3D Games, drifting, Driving, Math, Race",
            "thumb": "https://img.gamemonetize.com/souihl4o9tpdd7xadmz778waakj6m29m/512x384.jpg",
            "width": "640",
            "height": "360"
          },
          {
            "id": "32316",
            "title": "Merge Happy Planets!",
            "description": "Classic merge games are coming back with the Merge Happy Planets! Throw the planets and let them merge! Be careful for meteor showers and some mad planets! Use Big Bang Bombs, Rainbow Planets and Black Holes to achieve victory! Try your best. Can you be the number one!?",
            "instructions": "Mouse click to control planets",
            "url": "https://html5.gamemonetize.com/zt9kw0rl1ev6onqgiumhrcx2taik9ag6/",
            "category": "Puzzles",
            "tags": "1 Player, 2D, Arcade, Best, Brain, Crazy, Space",
            "thumb": "https://img.gamemonetize.com/zt9kw0rl1ev6onqgiumhrcx2taik9ag6/512x384.jpg",
            "width": "540",
            "height": "960"
          },
          {
            "id": "32315",
            "title": "Wall Of Danger Dash",
            "description": "A journey to save a Friend and to teach a lesson to the bad monster. Game story begins with Jake and Lily, among them Lily was snatched by a bad monster named Shadow Loomed. Monster gave a challenge to Jack to come and get his friend Lily. Jack is brave enough to accept the challenge. Now the player has to play the role of Jack and help toward his journey. There are many traps and monsters waiting for him but you have to overcome and kill all the enemies to save his friend. Features of this game: High Quality graphics Game size is minimal Super simple controls Interesting Quests",
            "instructions": "How to play Android Use On-Screen touch buttons to control the player PC Move Left Left Arrow Move Right Right Arrow Jump Up Arrow ",
            "url": "https://html5.gamemonetize.com/fvjp9obhregh67131r2fz47r2vsaoaoj/",
            "category": "Hypercasual",
            "tags": "2D, Adventure, Casual, Side Scrolling, survival",
            "thumb": "https://img.gamemonetize.com/fvjp9obhregh67131r2fz47r2vsaoaoj/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32305",
            "title": "Stick Man Battle Fighting",
            "description": "Enter the world of Stick Man Battle Fighting and every move matters! This casual fighting game lets you unleash your skills in dynamic stickman battles against enemies. Master various fighting styles and begin exciting single-mode combat. Choose a weapon and customize your hero.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/lyqrzmhsa4gdejjhe2c1fadi6uw6gaen/",
            "category": "Fighting",
            "tags": "Battle, Fight, Superhero",
            "thumb": "https://img.gamemonetize.com/lyqrzmhsa4gdejjhe2c1fadi6uw6gaen/512x384.jpg",
            "width": "1920",
            "height": "1080"
          },
          {
            "id": "32303",
            "title": "Mini Boxing",
            "description": "Mini Boxing is a fantastic super-deformed boxing game where you control the boxer Nick-Joe and help him win the Boxer Championship Tournament! To claim victory, youll need to face and defeat six unique and quirky boxers, each with their own fighting style. The game is incredibly easy to play, with three main moves: Mini Boxing is inspired by classic 1980s arcade games, featuring charming super-deformed graphics and smooth animations.",
            "instructions": "Key Controls Left Move Left Right Move Right UP Block Z classic light jab X medium straight punch C super heavy uppercut punch ",
            "url": "https://html5.gamemonetize.com/wgdaxwl09pqgakqlidsvnv9ah5wdk2lc/",
            "category": "Sports",
            "tags": "Arcade, Boxing, Fight, Fighting, HTML, HTML5, mapi games, Sport",
            "thumb": "https://img.gamemonetize.com/wgdaxwl09pqgakqlidsvnv9ah5wdk2lc/512x384.jpg",
            "width": "1280",
            "height": "600"
          },
          {
            "id": "32292",
            "title": "Advanture Of Tommy",
            "description": "Explore the adventure and fun game Adventure of Tommy In this adventure many mysterious traps and dangers were waiting for The main character Tommy Player has to play the role of Tommy and finish each level and collect gold coins. If you like genres such as adventure, action, rpg, platform, quests or just want to have a good time then this game is for you. Features of this game: High Quality graphics Game size is minimal Super simple controls Interesting Quests",
            "instructions": "How to play Android Use On-Screen touch buttons to control the player PC Move Left - Press ldquo Left Arrow rdquo Move Right - Press ldquo Right Arrow rdquo Jump - Press ldquo Up Arrow rdquo ",
            "url": "https://html5.gamemonetize.com/z9gmyi01i5dsgspg3lb1mdp5m882w1mt/",
            "category": "Hypercasual",
            "tags": "2D, Adventure, Casual, Platform, Side Scrolling",
            "thumb": "https://img.gamemonetize.com/z9gmyi01i5dsgspg3lb1mdp5m882w1mt/512x384.jpg",
            "width": "854",
            "height": "480"
          },
          {
            "id": "32285",
            "title": "Gold Miner Tower Defense",
            "description": "Gold Miner Tower Defense is a wonderful combination of tower defense, card strategy and upgrade combat. You will play as a brave miner protecting gold from enemies. Protect your precious treasures strategically with many unique defense towers!",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/ukcyc9vpff2jus19qjkt6gbxxoyo84qw/",
            "category": "Hypercasual",
            "tags": "Strategy, Tower Defense",
            "thumb": "https://img.gamemonetize.com/ukcyc9vpff2jus19qjkt6gbxxoyo84qw/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32284",
            "title": "Z Machine",
            "description": "In the Z-Machine, you will be in a vibrant arcade world. Take on hordes of zombies in a powerful tank! Your mission is to destroy the zombie and get the money, and then ship it back to the warehouse.Upgrade your vehicle at the same time to improve combat effectiveness and defense!",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/6pb58yxizvrukc8x8wvbf3tqnaoffbtq/",
            "category": "Hypercasual",
            "tags": "Car, survival, Zombie",
            "thumb": "https://img.gamemonetize.com/6pb58yxizvrukc8x8wvbf3tqnaoffbtq/512x384.jpg",
            "width": "720",
            "height": "1280"
          },
          {
            "id": "32281",
            "title": "Bottle Battle",
            "description": "This is a puzzle game, the game provides different volumes of the kettle, according to the game level prompts each kettle needs a different magnitude, to reach the required magnitude of the prompt can be cleared",
            "instructions": "The main control of the game is to click on the water bottle to pour water into another water bottle",
            "url": "https://html5.gamemonetize.com/a7fognb5ezg1z5x7x1b23f4t6z5tk1gc/",
            "category": "Hypercasual",
            "tags": "1 Player, 2D, Interactive, water",
            "thumb": "https://img.gamemonetize.com/a7fognb5ezg1z5x7x1b23f4t6z5tk1gc/512x384.jpg",
            "width": "1080",
            "height": "1920"
          },
          {
            "id": "32250",
            "title": "Neon Billard Pool",
            "description": "Play a game of pool to have fun and enjoy and play with a friend, each one plays a round, one is the yellow player and the other is the red player! Have fun!",
            "instructions": "Use the mouse to control and bounce the white ball ",
            "url": "https://html5.gamemonetize.com/bfy6fbzievlze6nan6vk7eyanal6ybaz/",
            "category": "Hypercasual",
            "tags": "2 Player, 8 Ball Pool, Ball, Best Games, Billiard, Casual, crash, Fun, Math",
            "thumb": "https://img.gamemonetize.com/bfy6fbzievlze6nan6vk7eyanal6ybaz/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32247",
            "title": "Offroad Truck Army Driving",
            "description": "In Offroad Truck Army Driving, players navigate rugged mountain terrains and diverse environments. With three types of military vehicles, players take on challenging missions across three unique packs. Experience intense off-road action, conquering steep hills, muddy trails, and rocky landscapes as you complete various army driving tasks, all while mastering the toughest off-road challenges. Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
            "instructions": "WASD drive",
            "url": "https://html5.gamemonetize.com/43fe8rc64mstpvw5zf24lct45nptj2mn/",
            "category": "Action",
            "tags": "Army, Driving, Mentolatux, mountain, offroad, Truck, trucks, Unity3D, WebGL",
            "thumb": "https://img.gamemonetize.com/43fe8rc64mstpvw5zf24lct45nptj2mn/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32246",
            "title": "Goodnight My Baby",
            "description": "Are you interested in the world of baby monsters? Do you want to know what the home of the baby monsters looks like when night falls? Our game can satisfy your curiosity. You can go into their home and put the baby to sleep.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/34sllctljd4vlu5n7cno56dm97mlfafb/",
            "category": "Action",
            "tags": "Monster, Panda",
            "thumb": "https://img.gamemonetize.com/34sllctljd4vlu5n7cno56dm97mlfafb/512x384.jpg",
            "width": "1920",
            "height": "1080"
          },
          {
            "id": "32237",
            "title": "Fishdom Online",
            "description": "Fishdom Online is a casual game full of fun and creativity. Various gameplay and mini-games bring you a rich gaming experience. Here includes elements such as puzzles, merges, mini-games and collections to make every game challenging and fun. Enjoy relaxing leisure time and improve your skills and strategic abilities through a variety of tasks and challenges.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/twy76rh160z329b5luaf0ca9p3a6egbl/",
            "category": "Puzzles",
            "tags": "Arcade, Games",
            "thumb": "https://img.gamemonetize.com/twy76rh160z329b5luaf0ca9p3a6egbl/512x384.jpg",
            "width": "640",
            "height": "1136"
          },
          {
            "id": "32234",
            "title": "Offroad Cargo Truck 2024",
            "description": "The 2024 Offroad Cargo Truck roars through lush forest trails, showcasing its rugged durability and advanced suspension. Equipped with high-clearance wheels and all-terrain tires, the driver expertly navigates winding paths. The truck&amp;rsquo;s spacious cargo area carries essential gear, while the vibrant greenery and serene environment enhance the adventurous spirit. Sunlight filters through towering trees, creating a perfect backdrop for offroad exploration. Web Dev &lt;a href='https://www.newcrazygames.com/'&gt;https://www.newcrazygames.com/&lt;/a&gt;",
            "instructions": "WASD drive",
            "url": "https://html5.gamemonetize.com/uayd9hwxcusn9oarblbqypwsq5g520xk/",
            "category": "Action",
            "tags": "Driving, Forest, Mentolatux, offroad, Simulator, Truck, Unity3D, WebGL",
            "thumb": "https://img.gamemonetize.com/uayd9hwxcusn9oarblbqypwsq5g520xk/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32225",
            "title": "Vehicle Transport Police Simulator",
            "description": "Vehicle Transport Police Simulator is an action-packed game where players drive various vehicles, including buses, jeeps, and trucks, to transport military personnel to their destinations. With challenging missions, diverse terrains, and realistic driving mechanics, players must navigate obstacles and ensure safe, timely delivery of troops while maintaining law and order. Web Dev &lt;a href='https://www.kizi10.org/'&gt;https://www.kizi10.org/&lt;/a&gt;",
            "instructions": "WASD to drive",
            "url": "https://html5.gamemonetize.com/sro33w89adskjdtlk9cwktqjdd1csm90/",
            "category": "Action",
            "tags": "Army, bus, Car, Cars, Driving, Mentolatux, military, Simulation, Truck, Unity3D, WebGL",
            "thumb": "https://img.gamemonetize.com/sro33w89adskjdtlk9cwktqjdd1csm90/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32198",
            "title": "Robot Fighting Adventure",
            "description": "Enter Robot Fighting Adventure which is filled with challenges in robot fighting! Here, the battle is the clash of fists and steel. But it is a test of wisdom and strength! As a robot warrior, you will explore the map and unlock more levels.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/riw72brknxjp2r5nx3oh8mh6pm2uqwd2/",
            "category": "Boys",
            "tags": "Battle, Fight, Interactive, Robot, transport",
            "thumb": "https://img.gamemonetize.com/riw72brknxjp2r5nx3oh8mh6pm2uqwd2/512x384.jpg",
            "width": "1334",
            "height": "750"
          },
          {
            "id": "32197",
            "title": "Superhero Transform Change Race",
            "description": "Are you ready to be a superhero and have a racing adventure? In Superhero Transform - Change Race,you should run and transform! In the challenging track, you will incarnate different superheroes. Use their special abilities to avoid obstacles and surpass opponents.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/xq2de54uthny5abw35tq1zxig2lq68k5/",
            "category": "Racing",
            "tags": "Casual, Interactive, running, Superhero",
            "thumb": "https://img.gamemonetize.com/xq2de54uthny5abw35tq1zxig2lq68k5/512x384.jpg",
            "width": "750",
            "height": "1630"
          },
          {
            "id": "32193",
            "title": "Archer Super Hero",
            "description": "action game, where the archer must cross the monster valley, where he encounters many challenges. Collect all the stars in each stage. Archer Hero is a super fun and challenging 2D side-scroller game in the same style as blockbuster games like Super Mario, Donkey Kong and Sonic. The game features 30 levels that will challenge you to beat it. The designs are very colorful and beautiful!",
            "instructions": "Run Right Key D Run Left Key A Attack Key K Jump Key W ",
            "url": "https://html5.gamemonetize.com/2ftxsmhh3aamnm4tjoylww9yehfyv8jk/",
            "category": "Adventure",
            "tags": "1 Player, Action, attack",
            "thumb": "https://img.gamemonetize.com/2ftxsmhh3aamnm4tjoylww9yehfyv8jk/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32186",
            "title": "Monster Trucks Sky Stunts",
            "description": "Monster Trucks Sky Stunts take extreme driving to the next level, as these powerful machines defy gravity with aerial maneuvers. In this high-octane event, monster trucks launch off massive ramps, soaring through the sky before landing with force. Drivers execute jaw-dropping stunts like flips, spins, and mid-air tricks, combining speed and precision. Sky stunts push both trucks and drivers to their limits, thrilling spectators with heart-pounding action and breathtaking jumps. Web Dev &lt;a href='https://www.bestcrazygames.com/'&gt;https://www.bestcrazygames.com/&lt;/a&gt;",
            "instructions": "WASD drive",
            "url": "https://html5.gamemonetize.com/7tyy3jn4nuy6tky2ewyy634azle68jd3/",
            "category": "Racing",
            "tags": "Car, Driving, Mentolatux, monstertruck, Racing, Stunts, Truck, Unity3D, WebGL",
            "thumb": "https://img.gamemonetize.com/7tyy3jn4nuy6tky2ewyy634azle68jd3/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32166",
            "title": "Car Park Simulator",
            "description": "Welcome to Car Park Simulator, Are you ready to test your driving skills and master the art of parking? Navigate through 72 challenging levels filled with obstacles and narrow spaces...",
            "instructions": "Use the cursor keys to move and steer the car or use the on-screen buttons to navigate the car to the target parking spot ",
            "url": "https://html5.gamemonetize.com/a7gt7aha4f2olhc0riv85xb30e69h23b/",
            "category": "Hypercasual",
            "tags": "Arcade, Car, Parking, Simulation, Skill",
            "thumb": "https://img.gamemonetize.com/a7gt7aha4f2olhc0riv85xb30e69h23b/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32165",
            "title": "Princesses of Quadrobics",
            "description": "Immerse yourself in the world of modern fashion trends with the game &amp;laquo;Princesses of Quadrobics&amp;raquo;! Two beautiful princesses, Eliza and the Little Mermaid, are waiting for your help in creating the perfect images for practicing Quadrobics. First, create a gentle but expressive makeup for each of the girls and put your hair in a comfortable hairstyle. Then choose dresses, jewelry and accessories. But the adventures dont end there! Complete the look by putting masks in the form of adorable animals on the princesses. After all, this is a mandatory part of the Quadrobics trend! Create unique images for your favorite heroines!",
            "instructions": "Depending on the gaming device a computer mouse click or a simple touch on touch screens is used to control it ",
            "url": "https://html5.gamemonetize.com/m98jydx82jjsvo0vhj2vnvtsahxm4981/",
            "category": "Sports",
            "tags": "Animal, Dress Up, Hair, Makeover",
            "thumb": "https://img.gamemonetize.com/m98jydx82jjsvo0vhj2vnvtsahxm4981/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32158",
            "title": "Red And Blue Stickman Spy Puzzles",
            "description": "Want to be an archery hero? Red And Blue Stickman-Spy Puzzles is an exciting and fun action puzzle game. In this game, you will play as a brave blue stickman in this special adventure! In each level, you will face red monsters. To use your agility to evade and defeat enemies. Seize the opportunity to constantly adjust your archery Angle and distance to defeat the red monster.",
            "instructions": "Mouse click or tap to play ",
            "url": "https://html5.gamemonetize.com/h2jx4nmc8znfmowd84tu9ljzdz9tfaf2/",
            "category": "Puzzles",
            "tags": "Adventure, Arcade, Stickman",
            "thumb": "https://img.gamemonetize.com/h2jx4nmc8znfmowd84tu9ljzdz9tfaf2/512x384.jpg",
            "width": "1136",
            "height": "640"
          },
          {
            "id": "32146",
            "title": "Stickman Troll Thief Puzzle",
            "description": "Thief Puzzle is a funny and addictive casual puzzle game. Here you should help a clever stickman with many funny ways to solve puzzles and steal target items. The rich and varied game scene contains many unexpected obstacles and traps, testing your intelligence and logical thinking. Each level of the game is a unique challenge.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/136zurog5im5u5grwaf19itso4bn8k6l/",
            "category": "Puzzles",
            "tags": "Stickman",
            "thumb": "https://img.gamemonetize.com/136zurog5im5u5grwaf19itso4bn8k6l/512x384.jpg",
            "width": "750",
            "height": "1334"
          },
          {
            "id": "32125",
            "title": "Logical Ball Escape",
            "description": "Amazing logical mind game with ball and box You have to help that ball to reach the door. Physics based logical thought needs to be solved. Don&amp;rsquo;t let the ball fall, either game will be over. If you like genres such as logical games, mind games or just want to have a good time then this game is for you. Features of this game: High Quality graphics will leave only positive feedback Game size is minimal (but not compromised the graphics) and it is good for your device health Super simple controls make it easy to start playing Interesting quests will not let you get bored while playing the game.",
            "instructions": "How to play Android Use On-Screen touch and tap PC Use Mouse Left Click to tap ",
            "url": "https://html5.gamemonetize.com/5x3c8p5fne5r57bz2olo8fj9usg5fwux/",
            "category": "Puzzles",
            "tags": "2D, Adventure, Casual, Puzzle",
            "thumb": "https://img.gamemonetize.com/5x3c8p5fne5r57bz2olo8fj9usg5fwux/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32124",
            "title": "Army Bus Driving 2024",
            "description": "Army Bus Driving 2024 immerses players in the challenging world of military transport. Take command of rugged, all-terrain army buses, transporting soldiers and equipment across hostile environments. Navigate through treacherous landscapes, from deserts to mountains, under tight schedules and demanding conditions. With realistic physics, detailed military vehicles, and a variety of missions, this simulation game offers a thrilling and authentic experience for those ready to handle the pressures of army logistics on the road.",
            "instructions": "WASD drive",
            "url": "https://html5.gamemonetize.com/x768a5mk67izz2hv8kxhucmlw49i2ane/",
            "category": "Action",
            "tags": "Action, Army, bus, Driving, Mentolatux, Unity3D, WebGL",
            "thumb": "https://img.gamemonetize.com/x768a5mk67izz2hv8kxhucmlw49i2ane/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32113",
            "title": "Car Stunt Ramp Challenge",
            "description": "Car Stunt Ramp Challenge is an adrenaline-pumping racing game that puts your driving skills to the ultimate test. Get behind the wheel of high-speed cars and take on extreme ramps and obstacle courses designed to push your limits. The game features a variety of stunts, from high-flying jumps to complex flips, all set in vibrant, dynamic environments. Precision and timing are crucial as you race against the clock to complete each challenge. With its realistic physics and thrilling gameplay, Car Stunt Ramp Challenge offers a heart-pounding experience for stunt driving enthusiasts. Are you ready to conquer the ramps?",
            "instructions": "WASD drive",
            "url": "https://html5.gamemonetize.com/omk3dx57djeoq1l1b8kj1r0mz43trze3/",
            "category": "Action",
            "tags": "Car, Driving, Mentolatux, Stunts, Supercars, Unity3D, WebGL",
            "thumb": "https://img.gamemonetize.com/omk3dx57djeoq1l1b8kj1r0mz43trze3/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32111",
            "title": "Big wheels",
            "description": "Collect the lying wheels along the way, then the wheels of your bike will increase and you will be able to drive through any obstacles.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/7tauq7353xgbawdhttbtpag95t6wdg0v/",
            "category": "Arcade",
            "tags": "3D, Arcade, Bicycle, HTML5",
            "thumb": "https://img.gamemonetize.com/7tauq7353xgbawdhttbtpag95t6wdg0v/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32071",
            "title": "Letter Ball",
            "description": "The addictive adventure game Letter Ball is about fun simple control, mind-blowing puzzles, and a fun brain workout! You control the ball and hit the letters to make words. Your task is to collect the words in the right order, Each level is more difficult than the previous one because you need to make an effort to get the necessary items. Jump, roll and avoid obstacles. Become the best Letter Ball player!",
            "instructions": "Use W A S D or Arrow Keys To Move the Game Use Space to Jump",
            "url": "https://html5.gamemonetize.com/93ttbeq0wocddpfxnjeofu35itvaj7v4/",
            "category": "Puzzles",
            "tags": "Action, Adventure, Ball, Boy, jump, Puzzle",
            "thumb": "https://img.gamemonetize.com/93ttbeq0wocddpfxnjeofu35itvaj7v4/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32069",
            "title": "Frogy Ninja 2D",
            "description": "Step into the Year of the Frog, where the tables turn, and the hunter becomes the hunted! As a once-peaceful frog, youve escaped the ninjas and are now out for revenge. Unleash your kung fu skills in this turn-based action-puzzle game. Navigate hundreds of challenging levels with intuitive swipe controls, enjoy vibrant pixel art, and interact with diverse environments to outsmart enemy ninjas and complete your mission. Web Dev &lt;a href='https://www.crazygamesx.com/'&gt;https://www.crazygamesx.com/&lt;/a&gt;",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/atmt7anprzlysj20bff1vt7ajavb2457/",
            "category": "Arcade",
            "tags": "2D, Casual, Click, frog, Mentolatux, Ninja, swipe",
            "thumb": "https://img.gamemonetize.com/atmt7anprzlysj20bff1vt7ajavb2457/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32060",
            "title": "Polygon Flight Simulator",
            "description": "Fly like a pro in this airplane simulator; you can smoothly navigate from one airport to another. Its straightforward&amp;mdash;press the W button, drag the mouse, and watch your plane ascend. Skillfully enter airborne checkpoints en route to your next destination airport. With intuitive controls, this simulator provides an immersive flying experience. Whether youre a novice or an expert, the seamless interface ensures an enjoyable journey through virtual skies. Master the art of aviation with this user-friendly simulator. Website Developer &lt;a href='https://www.crazygamesonline.com'&gt;https://www.crazygamesonline.com&lt;/a&gt;",
            "instructions": "WASD move plane Mouse Drag go up Mouse Forwad go Down Mouse Left or right move plane TAB Menu",
            "url": "https://html5.gamemonetize.com/jxcgkmz8u2tpdhvvfed9ecl25bqkspxt/",
            "category": "Action",
            "tags": "Aircraft, airplane, Driving, fly, Mentolatux, Unity3D, vehicle, WebGL",
            "thumb": "https://img.gamemonetize.com/jxcgkmz8u2tpdhvvfed9ecl25bqkspxt/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32047",
            "title": "Shadow Strike",
            "description": "Kill the enemies to complete the mission, Collect stars to reach the next destination. Explore the enemy world and danger enemies, execute them and more exciting challenges waiting for the player. Be Careful! While exploring the enemy world as those enemies have deadly weapons is very dangerous and deadly traps can harm the player and game will be over. If you like genres such as adventure, action, rpg, platform, quests or just want to have a good time then this game is for you. Features of this game: High Quality graphics. Game size is minimal. Super simple controls. Interesting quests.",
            "instructions": " How to play Android Use On-Screen touch buttons to control the player PC Move Left - Press ldquo A rdquo Move Right - Press ldquo D rdquo Jump - Press ldquo W rdquo Jet-Pack - Press ldquo J rdquo Shoe-Jet - Press ldquo K rdquo Fire - Press ",
            "url": "https://html5.gamemonetize.com/hb4yues17l7d0zk62gmctcm796ky2e0e/",
            "category": "Hypercasual",
            "tags": "2D, Adventure, Casual, Platform",
            "thumb": "https://img.gamemonetize.com/hb4yues17l7d0zk62gmctcm796ky2e0e/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32046",
            "title": "Draw Cute Animals",
            "description": "Fun drawing game for kids! Draw and color cute animals while boosting creativity and learning. Draw Cute Animals Game is an entertaining and educational game designed to spark creativity in young minds. This game allows children to draw and color a wide range of cute animals, from cuddly kittens to playful pandas. With easy-to-follow guides and vibrant color options, kids can create their own masterpieces while learning about different animals. Ideal for preschoolers and toddlers, this game not only enhances artistic skills but also improves hand-eye coordination. Whether your child loves to doodle or is just starting to explore art, Draw Cu",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/u7zmx2kyw5eehgxrykrsyo52y25vjv4e/",
            "category": "Puzzles",
            "tags": "Baby Games, Best, Best Games, Game, Games, html5 games, Kid, Kids, NapTech Games, Online",
            "thumb": "https://img.gamemonetize.com/u7zmx2kyw5eehgxrykrsyo52y25vjv4e/512x384.jpg",
            "width": "1280",
            "height": "720"
          },
          {
            "id": "32034",
            "title": "Bus School Park Driver",
            "description": "Take the wheel in Bus School Park Driver! Master the art of bus driving with precision parking, smooth navigation, and real-world traffic challenges. Whether you&amp;rsquo;re maneuvering through tight school zones or handling complex parking scenarios, every level tests your skills. Perfect your driving techniques, complete engaging missions, and become the ultimate school bus driver. Web Dev &lt;a href='https://www.coolcrazygames.com/'&gt;https://www.coolcrazygames.com/&lt;/a&gt;",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/i429yunzarak64517ysvdeki741nrdoc/",
            "category": "Arcade",
            "tags": "Adventure, Arcade, bus, Car, Driving, Unity3D, WebGL",
            "thumb": "https://img.gamemonetize.com/i429yunzarak64517ysvdeki741nrdoc/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32031",
            "title": "The Last Shot",
            "description": "Step into the mystical world of The Last Shot, where your precision and strategy are put to the ultimate test. Guide the mystical orb through intricate levels filled with glowing platforms and obstacles, all set against a beautifully crafted twilight backdrop.",
            "instructions": "Drag your finger to aim the orb towards the platforms Release to shoot the orb and guide it to the green platform Avoid obstacles and use the blue platforms strategically to reach your goal Complete all levels to become the master of The Last Shot The",
            "url": "https://html5.gamemonetize.com/jx55tyrf879uilkgs7zqes0u7muesrfr/",
            "category": "Shooting",
            "tags": "1 Player, 2048, 2D, Air, Android, Best, Best Games, Bubble Shooter",
            "thumb": "https://img.gamemonetize.com/jx55tyrf879uilkgs7zqes0u7muesrfr/512x384.jpg",
            "width": "1080",
            "height": "1920"
          },
          {
            "id": "32023",
            "title": "Jelly Matches",
            "description": "Welcome to the ultimate color-matching challenge! In this engaging puzzle game, your goal is to break a specified number of colored pieces on the board. The game features special pieces with unique properties, including x2 (which doubles the number of broken pieces), -1 (which removes one piece from your total), and shield pieces that offer protection.",
            "instructions": "Drag with mouse or touch to drop a piece and have it fall and be matched",
            "url": "https://html5.gamemonetize.com/45k2hkp92lx338zqlj5m2xr3puexfduw/",
            "category": "Puzzles",
            "tags": "Color, Match 3, Match3, Matching, Math, Physics, Puzzle, puzzleblock",
            "thumb": "https://img.gamemonetize.com/45k2hkp92lx338zqlj5m2xr3puexfduw/512x384.jpg",
            "width": "640",
            "height": "1280"
          },
          {
            "id": "32021",
            "title": "Bridge Wars",
            "description": "Gear up for Bridge Wars, a mobile gaming epic blending creativity and strategy. Immerse in a world where tactical mastery meets artistic flair. Draw patterns, summon stickman defenders in 3 seconds, facing relentless enemy vehicles. Upgrade characters, navigate intense environments, and stop foes from breaching. Embark on a thrilling adventure, showcasing tactical mastery against relentless waves! 1. Unlimited Levels 2. Customizable Characters and Weapons Skins 3. Power up Arsenal with shields and landmines and many more",
            "instructions": "HOW TO PLAY Bridge Wars 1 Draw a pattern to spawn your soldiers within 3 seconds 2 The soldiers spawned will attack the enemy vehicles who will try to cross the bridge full of barricades 3 Visit the shop to upgrade your arsenal such as armor weapons",
            "url": "https://html5.gamemonetize.com/wf6qo75w4eyuvylmnpezdk3ord3ajdlr/",
            "category": "Hypercasual",
            "tags": "Battle, Casual, Hypercasual",
            "thumb": "https://img.gamemonetize.com/wf6qo75w4eyuvylmnpezdk3ord3ajdlr/512x384.jpg",
            "width": "800",
            "height": "600"
          },
          {
            "id": "32019",
            "title": "Hide And Seek Blue Monster",
            "description": "Enter a world full of mystery and fun. In Hide And Seek: Blue Monster, you will experience a unique hide-and-seek adventure. The game combines casual games with puzzle challenges.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/digi5xwq9xlsyolubtfl4ofqyxnc7a02/",
            "category": "Puzzles",
            "tags": "Escape, Hidden, running",
            "thumb": "https://img.gamemonetize.com/digi5xwq9xlsyolubtfl4ofqyxnc7a02/512x384.jpg",
            "width": "640",
            "height": "1334"
          },
          {
            "id": "32010",
            "title": "Pull The Pin Much Money",
            "description": "Pull The Pin - Much Money is a classic math pin puzzle game. In the game, you need to pull out the pin that can make you earn more cash. This game not only tests your thinking ability but also your math skills.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/u6y73ibps2wn7w3o4z135ppn4rsrx588/",
            "category": "Puzzles",
            "tags": "Math, Monkey",
            "thumb": "https://img.gamemonetize.com/u6y73ibps2wn7w3o4z135ppn4rsrx588/512x384.jpg",
            "width": "640",
            "height": "1136"
          },
          {
            "id": "31990",
            "title": "Super Doctor Body Examination",
            "description": "Super Doctor Body Examination is an educational game. Do you hate every physical examination, but the physical examination is for your health.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/2iwba0rqln0grbzu8wxt3pphl1yrbe9e/",
            "category": "Boys",
            "tags": "Doctor, Educational",
            "thumb": "https://img.gamemonetize.com/2iwba0rqln0grbzu8wxt3pphl1yrbe9e/512x384.jpg",
            "width": "1080",
            "height": "1920"
          },
          {
            "id": "31989",
            "title": "Perfect Piano Magic",
            "description": "Perfect Piano Magic is a fun casual game. If you are a big fan of Piano Magic games, then this is a game you cant afford to miss! The gameplay of this game is extremely similar to Piano Magic.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/yxvf7gwdccvrfkoykfngpnmhkjox49gr/",
            "category": "Hypercasual",
            "tags": "1 Player, Music, Shoot",
            "thumb": "https://img.gamemonetize.com/yxvf7gwdccvrfkoykfngpnmhkjox49gr/512x384.jpg",
            "width": "750",
            "height": "1334"
          },
          {
            "id": "31982",
            "title": "Weight Puzzle 3D",
            "description": "Welcome to Weight Puzzle 3D Game! Lift the weights and place them in the correct sections of the road to balance the weights, allowing the player to run and reach the finish line. Lets see if youre good at math and numbers in 3D amazing cool game!",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/z62h7dsza71b8957l45wgpqq652ttour/",
            "category": "Puzzles",
            "tags": "3D, 3D Games, Physics, Puzzle, Unity3D",
            "thumb": "https://img.gamemonetize.com/z62h7dsza71b8957l45wgpqq652ttour/512x384.jpg",
            "width": "350",
            "height": "600"
          },
          {
            "id": "31971",
            "title": "Uno Online",
            "description": "UNO Online is a strategy card game. In the game, the first player to clear his hand will become the winner. In order to win, you need to guess the deck of cards in your opponents hands and arrange your card order reasonably.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/wkxitscgm7t9szdj938hq6bs10ms7rse/",
            "category": "Hypercasual",
            "tags": "Arcade, Card",
            "thumb": "https://img.gamemonetize.com/wkxitscgm7t9szdj938hq6bs10ms7rse/512x384.jpg",
            "width": "1920",
            "height": "1080"
          },
          {
            "id": "31954",
            "title": "Hole Io 2",
            "description": "Hole.io is a super addictive free arcade game. If you were to ask what game is the most popular these days? Then its the black hole devouring game that has exploded in popularity worldwide.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/77h4sbaucufl8hc03nna7anwjem7mtop/",
            "category": "Arcade",
            "tags": ".io, Battle",
            "thumb": "https://img.gamemonetize.com/77h4sbaucufl8hc03nna7anwjem7mtop/512x384.jpg",
            "width": "1080",
            "height": "1920"
          },
          {
            "id": "31953",
            "title": "Granny Chapter 3 High School",
            "description": "Chapter 3: High School Escape from Granny is a fun third-person horror game where you must hide from Granny and the specters of Slendrina. Discover the keys to the abandoned schools classrooms, stock up on ammo, and try to stay alive for as long as possible. Find the revolver loaded with ammo and all the keys to the classrooms of the vacant school while Granny and Slendrina chase you. As you go inside the classroom, youll see a single desk that reads Granny and the keys to the locked door. Finally, Slendrina or Granny makes the player",
            "instructions": "The main objective of the game is to try to survive as long as you can by collecting items to hide and escape from Granny Controls WASD to move Right click to shoot F Interact Esc Menu",
            "url": "https://html5.gamemonetize.com/ehnw198451t7cygffbjrs4ac50ue011k/",
            "category": "Action",
            "tags": "Escape, kiz10, Room, Shooting, survival",
            "thumb": "https://img.gamemonetize.com/ehnw198451t7cygffbjrs4ac50ue011k/512x384.jpg",
            "width": "900",
            "height": "600"
          },
          {
            "id": "31949",
            "title": "Fall Fu Panda",
            "description": "Fall Fu Panda is a captivating game where the world revolves, and your character must fall into place. In this game, you&amp;rsquo;ll experience dynamic rotating physics, allowing you to zoom out and view the entire world, track your players position, and follow their every move as they navigate the environment. The goal is to land on objects to score points or defeat enemies. Picture your hero trying to escape from a dungeon or cave, overcoming obstacles in a constantly shifting world. To play, simply tap the left or right side of the screen to rotate the world, making your character fall onto the next block. If they miss and fall off, it&amp;rsquo;s game ove",
            "instructions": "Touch Left Key Left Left Rotate Left Touch Right Key Right Rotate Right ",
            "url": "https://html5.gamemonetize.com/6kx65r64kuooctsuln4qpdyzqqmdfh00/",
            "category": "Puzzles",
            "tags": "Arcade, Fall Boys, Family, HTML, mapi games, Mobile, Panda, Puzzle",
            "thumb": "https://img.gamemonetize.com/6kx65r64kuooctsuln4qpdyzqqmdfh00/512x384.jpg",
            "width": "1280",
            "height": "600"
          },
          {
            "id": "31946",
            "title": "My City Hospital",
            "description": "My City Hospital is a sophisticated digital doll placement game. Have you ever thought about being a doctor or running a hospital to help others?",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/h1klvtd268m5dea93y1zwuntir8bqay1/",
            "category": "Hypercasual",
            "tags": "Doctor, Simulation",
            "thumb": "https://img.gamemonetize.com/h1klvtd268m5dea93y1zwuntir8bqay1/512x384.jpg",
            "width": "1600",
            "height": "768"
          },
          {
            "id": "31944",
            "title": "Space Survivor Shooting",
            "description": "Space Survivor Shooting is a challenging online game. As an impostor, your task is to plan, move fast, and use your shooting skills to defeat enemies and save allies. Use your intelligence and tactics to kill your opponents quickly.",
            "instructions": "Mouse click or tap to play",
            "url": "https://html5.gamemonetize.com/fgc6kf9h3gtckyse3xsoqx3tubxzg4g5/",
            "category": "Shooting",
            "tags": "Battle, Shoot",
            "thumb": "https://img.gamemonetize.com/fgc6kf9h3gtckyse3xsoqx3tubxzg4g5/512x384.jpg",
            "width": "1136",
            "height": "640"
          }
        ]
   
        setData(gameData);
        
    }, []);

  

    return (
      <div>
          {/* Navbar */}
          <div className="navbar">
              <div className="brand">GamePortal</div>
              <div className="navbar-content">
                  <input
                      type="text"
                      placeholder="Search Games"
                      value={searchQuery}
                      onChange={handleSearch}
                  />
                  <div className="hamburger" onClick={toggleMenu}>
                      &#9776; {/* Hamburger icon */}
                  </div>
                  <nav className={menuOpen ? 'open' : ''}> {/* Apply 'open' class when menu is open */}
                      <Link to="/">Home</Link>
                      <Link to="/TrendingGames">Trending Games</Link>
                  </nav>
                  <div className="social-links">
                      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-facebook"></i>
                      </a>
                      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-twitter"></i>
                      </a>
                      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-instagram"></i>
                      </a>
                  </div>
              </div>
          </div>

          {/* Game Cards Section */}
          <div className="game-section">
              <h2>Trending Games</h2>
              <div className="game-cards">
                  {filteredGames.map((game) => (
                      <div
                          key={game.id}
                          className="game-card"
                          onClick={() => navigate(`/play-game/${game.id}`)} // Navigate to Play Game page
                          style={{ cursor: 'pointer' }} // Show pointer on hover
                      >
                          <img src={game.thumb} alt={game.title} />
                          <p>{game.title}</p>
                      </div>
                  ))}
              </div>
          </div>

          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
          />
      </div>
  );
};

export default Trending;
