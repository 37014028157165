import React from 'react';
import '../bottom.css'; // Ensure this path is correct

const PrivacyPolicy = () => {
  return (
    <div className="main-container"> {/* This wraps the content and bottom bar */}
      <div className="content"> {/* This takes up the available space */}
        <h1 style={{ color: '#ffcc00' }}>Privacy Policy</h1>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          At PlaySeed Games, your privacy is important to us. We are committed to protecting your personal information while providing you with a safe and enjoyable gaming experience.
        </p>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          This policy outlines how we collect, use, and protect your personal data. By using our website and services, you agree to the terms of this Privacy Policy.
        </p>
        <h2 style={{ color: '#333' }}>Information We Collect</h2>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          We may collect personal information such as your name, email address, and usage data to enhance your gaming experience. We do not share your data with third parties without your consent.
        </p>
        <h2 style={{ color: '#333' }}>How We Protect Your Data</h2>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          We use the latest encryption methods and security protocols to ensure that your data is kept safe and secure.
        </p>
      </div>

     
    </div>
  );
};

export default PrivacyPolicy;
