import React from 'react';
import '../bottom.css'; // Ensure this path is correct

const AboutUs = () => {
  return (
    <div className="main-container"> {/* This wraps the content and bottom bar */}
      <div className="content"> {/* This takes up the available space */}
        <h1 style={{ color: '#ffcc00' }}>About Us</h1>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          Welcome to PlaySeed Games! We are dedicated to providing the best free-to-play games experience. Our mission is to bring joy and entertainment to gamers of all ages with a wide range of games available on our platform.
        </p>
        <p style={{ lineHeight: '1.6', color: '#555' }}>
          PlaySeed Games is designed to be user-friendly and accessible. Explore, play, and have fun with our endless selection of games!
        </p>
      </div>

    </div>
  );
};

export default AboutUs;
